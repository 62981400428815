const wrong = () => {
  let audio = new Audio(`https://maharttafl.com/sound/horn.mp3`);
  audio.play();
};
const clapp = () => {
  let audio = new Audio(`https://maharttafl.com/sound/clapp.mp3`);
  audio.play();
};

const DefaultAudio = {
  wrong,
  clapp,
};

export default DefaultAudio;
