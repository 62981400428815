import React from "react";
import Card from "../../../UI/Card";
import {
	CardContent,
	CardMedia,
	Rating,
	Typography,
	Button,
	Avatar,
	Box,
} from "@mui/material";
import "./ExpandCard.css";
import { useNavigate } from "react-router-dom";
import { Star } from "@mui/icons-material";

const ExpertCard = ({ expert }) => {
	const navigate = useNavigate();
	const onClick = (id) => navigate(`/teachers/${id}`);
	return (
		<Card className="expert-card">
			<CardContent>
				<Avatar src={`https://maharttafl.com/images/${expert.avatar}`} />
				<Box
					my={1.5}
					display={"flex"}
					justifyContent={"space-between"}
					gap={0.5}
				>
					<div className="info-wrap">
						<Typography variant="h6">{expert.name}</Typography>
						<div>{expert.about}</div>
					</div>
					<Box
						justifyContent={"center"}
						mb={1.5}
						display={"flex"}
						alignItems={"center"}
						gap={0.2}
						className="rating"
					>
						<Star />({expert.average_rating ??0})
					</Box>
				</Box>
				<Button
					variant="contained"
					className="gobtn"
					color="primary"
					onClick={()=>onClick(expert.id)}
				>
					اشتراك
				</Button>
			</CardContent>
		</Card>
	);
};

export default ExpertCard;
