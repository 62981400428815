import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import categoryService from "../../../redux/services/categories/Category/categoryService";
// import Card from "../../UI/Card";
import {
  Button,
  Card,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  useMediaQuery,
} from "@mui/material";

import "./exams.css";

import ContactUs from "../../../components/ContactUs";
import HeroSection from "../../../components/HeroSection";

import OurProgrames from "../../../components/OurPrograms";
import Hero1 from "../../../components/images/hero1.png";
import Tag from "../../../components/images/image 11.png";
import Line from "../../../components/images/pngegg (1) 1.png";
import examsServices from "../../../redux/services/exams/programs";

const Exams = () => {
  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate()
  useEffect(() => {
    examsServices.main("exams").then((res) => {
      setCategory(res.data.exams);
      // //console.log(res.data);
      setIsLoading(false);
    });
  }, [isLoading]);
  if (isLoading) return <></>;
  const title = (
    <>
      علم{" "}
      <span>
        {" "}
        <img className="tag" src={Tag} alt="" /> طفلك{" "}
      </span>{" "}
      <br /> الكلام و النطق اونلاين
      <br /> مع{" "}
      <span>
        {" "}
        البرنامج التخاطبي{" "}
        <span>
          الناطق <img className="line" src={Line} alt="" />{" "}
        </span>{" "}
      </span>
    </>
  );
  const subtitle = (
    <>
      علي يد نخبه من<span> خبراء التخاطب </span> و النطق و تنميه <br />
      مهارات الاطفال
    </>
  );
  return (
    <div className="takative__page">
      <HeroSection
        title={title}
        subtitle={subtitle}
        image={Hero1}
        btnColor="#ADD6E5"
      />
      <div className="container">
        <OurProgrames />
      </div>
      <section className="learning__program__secttion">
        <Card style={{ textAlign: "center" }}>
          <Button
            className="games__button"
            onClick={() => {navigate('/exams/results')}}
            style={{ width: "50%" }}
          >
            My Results
          </Button>
        </Card>
        <Card>
          <div className="learning__container">
            {category.map((e, index) => {
              return (
                <Link to={`/exams/show/${e.id}`}>
                  <div className={`leaning__box opened`}>
                    <div className="learning__image">
                      <img
                        src={`https://maharttafl.com/images/${e.avatar}`}
                        alt=""
                      />
                    </div>
                    <p className="leaning__title">{e.name}</p>
                  </div>
                </Link>
              );
            })}
          </div>
        </Card>
      </section>
      <ContactUs />
    </div>
  );
};

export default Exams;
