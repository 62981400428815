import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// import Card from "../../UI/Card";
import { Card } from "@mui/material";

import "./SubProgram.css";
import ProgramsSerivce from "../../../redux/services/categories/Programs/programs";

const SubProgram = () => {
  const { id, name, program } = useParams();
  //console.log(id, name, program);
  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const sub = ProgramsSerivce.subProgram( name, id).then((res) => {
      setCategory(res.data.defines);
    });
  }, [isLoading]);

  useEffect(() => {}, [isLoading]);

  return (
    <section className="learning__program__secttion">
      <Card>
        <div className="learning__container">
          {category.map((t) => {
            // //console.log(t[program]);
            return (
              <>
                {t[program] ? (
                  <Link
                    to={`/programs/category/${program}/${name}/${t.id}/${t[program].id}`}
                    state={t.photo}
                  >
                    <div className={`leaning__box opened`} key={t.id}>
                      <div className="learning__image">
                        <img
                          src={`https://maharttafl.com/images/${t.avatar}`}
                          alt=""
                        />
                      </div>
                      <p className="leaning__title">{t.name}</p>
                    </div>
                  </Link>
                ) : (
                  <></>
                )}
              </>
            );
          })}
        </div>
      </Card>
    </section>
  );
};

export default SubProgram;
