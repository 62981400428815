import axiosRequest from "../../../../helpers/axiosRequest";
import env from "../../../../helpers/env";
const API_URL = env.baseURL;
const subPrograms = (url) => {
  return axiosRequest.get(API_URL + url);
};
const program = (url, id) => {
  return axiosRequest.get(API_URL + url + "/" + id);
};

const JobsService = {
  subPrograms,
  program,
};

export default JobsService;
