import React, { useState, useEffect } from "react";

import "./SubProgramNegation.css";
import Card from "../../../../../UI/Card";

import { Link, useLocation } from "react-router-dom";
import negationServices from "../../../../../redux/services/categories/negation/programs";

const SubProgramNegation = (props) => {
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [category, setCategory] = useState({
    avatar: "",
    id: 1000,
    name: "المعكوسات",
  });
  const [programsId, setProgramsId] = useState({
    adject: null,
    verb: null,
    name: null,
  });
  useEffect(() => {
    negationServices.main("negation").then((res) => {
      setProgramsId(res.data);
      setIsLoading(false);
    });
    // //console.log(programsId);
  }, [isLoading]);
  if (isLoading) return <></>;

  return (
    <section className="prices__section">
      <div className="container" style={{ marginTop: 10 }}>
        <div className="prices__content">
          <Card radius="20px">
            <div className="single__program__box">
              <div className="program__icon">
                {/* <Headphone /> */}
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <div className="programe__title">{category.name}</div>

              {/* /programs/category/define/:name/:id */}
              <Link
                className="program__details__btn"
                to={`/programs/negation/nadjective/${programsId.adject}`}
                state={category}
              >
                نفي الصفات
              </Link>
              <Link
                className="program__details__btn"
                to={`/programs/negation/nverb/${programsId.verb}`}
                state={category}
              >
                نفي الأفعال
              </Link>
              <Link
                className="program__details__btn"
                to={`/programs/negation/nname/${programsId.name}`}
                state={category}
              >
                نفي الاسماء
              </Link>
            </div>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default SubProgramNegation;
