import axiosRequest from "../../../../helpers/axiosRequest";
import env from "../../../../helpers/env";
const API_URL = env.baseURL;

const program = (url, id) => {
  return axiosRequest.get(API_URL + url);
};

const RelationsServices = {
  program,
};

export default RelationsServices;
