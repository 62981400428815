import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import ProgramsSerivce from "../../../../redux/services/categories/Programs/programs";
import "./Choosing.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Grid } from "@mui/material";
import Carousel from "../../../../components/Carousel";
import { Item } from "../../../../components/Carousel/styles";
import kraaServices from "../../../../redux/services/kraa/programs";
import Box from "../../../../components/Box";
import DefaultAudio from "../../../../helpers/defaultAudio";
const Tamyeez7roof = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id, page } = useParams();
  const [nextBack, setNextBack] = useState({
    next: null,
    back: null,
  });
  const [images, setImages] = useState([]);

  const [sound, setSound] = useState({
    choose: null,
    right: null,
    wrong: null,
  });
  const [rightLetter, setRightLetter] = useState("");
  useEffect(() => {
    kraaServices.main(page, id).then((res) => {
      setSound({
        choose: `https://maharttafl.com/sound/${res.data.sound.choose}`,
        right: `https://maharttafl.com/sound/${res.data.sound.right}`,
        wrong: `https://maharttafl.com/sound/${res.data.sound.wrong}`,
      });
      setImages(res.data.tamiez.images);
      //console.log(res.data);

      if (page === "ekhtarkelma") {
        setRightLetter(res.data.tamiez.right);
      } else if (page === "sourawekelma") {
        setRightLetter(res.data.tamiez.avatar);
      } else {
        setRightLetter(res.data.tamiez.right1);
      }
      // setImages(res.data.chooses.images);
      // setNextBack({ ...nextBack, next: res.data.next });
      // setNextBack({ ...nextBack, back: res.data.previous });
      setIsLoading(false);
    });
  }, []);
  // const chooseAudio = async () => {
  //   let audio = new Audio(sound.choose);
  //   await audio.play();
  // };
  // const chooseAudioItem = async () => {
  //   let audio2 = new Audio(sound.item);
  //   audio2.play();
  // };
  // const clapp = () => {
  //   let audio = new Audio(`https://maharttafl.com/sound/clapp.mp3`);
  //   audio.play();
  // };
  if (isLoading) return <></>;

  return (
    <div className="games__page">
      <Grid
        container
        className="games__section container"
        justifyContent="center"
        textAlign="center"
        spacing={3}
      >
        {page === "sourawekelma" ? (
          <Grid item xs={12}>
            <img
              src={`https://maharttafl.com/images/${rightLetter}`}
              alt=""
              style={{
                width: "150px",
                height: "150px",
                border: "1px solid #dedede",
                borderRadius: "30%",
              }}
            />
          </Grid>
        ) : (
          <Grid item xs={12} className="letterBox">
            <Box text={rightLetter} disabled={true}></Box>
          </Grid>
        )}
        {images.map((e, index) => {
          return (
            <Grid item xs={3} className="letterBox">
              <Box
                text={e.image}
                onClick={() => {
                  if (e.type === "wrong") {
                    let audio = new Audio(sound.wrong);
                    audio.play();
                  }
                  if (e.type === "right") {
                    DefaultAudio.clapp();
                    setTimeout(() => {
                      let audio = new Audio(sound.right);
                      audio.play();
                    }, 2500);
                  }
                }}
              ></Box>
            </Grid>
          );
        })} 
      </Grid>
      {/* <div>
        <button
          style={{ width: "100%" }}
          onClick={() => {
            chooseAudio();
            setTimeout(() => {
              chooseAudioItem();
            }, 1300);
          }}
        >
          Listen
        </button>
        <Carousel className="games__section container" keys={false}>
          {images.map((t, index) => {
            return (
              <Item>
                <img
                  src={`https://maharttafl.com/images/${t.image}`}
                  alt=""
                  style={{
                    width: "150px",
                    height: "150px",
                    border: "1px solid #dedede",
                    borderRadius: "30%",
                  }}
                  onClick={() => {
                    if (t.type === "wrong") {
                      let audio = new Audio(sound.wrong);
                      audio.play();
                    }
                    if (t.type === "right") {
                      clapp();
                      setTimeout(() => {
                        let audio = new Audio(sound.right);
                        audio.play();
                      }, 2500);
                    }
                  }}
                />
              </Item>
            );
          })}
        </Carousel>
      </div> */}
    </div>
  );
};

export default Tamyeez7roof;
