import React, { useState, useEffect } from "react";

import "./SubProgramTafdil.css";
import Card from "../../../../../UI/Card";

import { Link, useLocation } from "react-router-dom";

import voiceServices from "../../../../../redux/services/categories/voice/programs";
import muzakrServices from "../../../../../redux/services/categories/muzakr/programs";

const SubProgramMuzakr = (props) => {
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [category, setCategory] = useState({
    avatar: "",
    id: 1000,
    name: "المذكر والمؤنث",
  });
  const [programsId, setProgramsId] = useState({
    define: null,
    choose: null,
  });
  useEffect(() => {
    muzakrServices.main("muzakar").then((res) => {
      // //console.log(res.data);
      setProgramsId(res.data);
      setIsLoading(false);
    });
    // //console.log(programsId);
  }, [isLoading]);
  if (isLoading) return <></>;

  return (
    <section className="prices__section">
      <div className="container" style={{ marginTop: 10 }}>
        <div className="prices__content">
          <Card radius="20px">
            <div className="single__program__box">
              <div className="program__icon">
                {/* <Headphone /> */}
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <div className="programe__title">{category.name}</div>

              {/* /programs/category/define/:name/:id */}
              <Link
                className="program__details__btn"
                to={`/programs/muzakr/define`}
                state={category}
              >
                التعريف
              </Link>
              <Link
                className="program__details__btn"
                to={`/programs/muzakr/choose/${programsId.choose}`}
                state={category}
              >
                الاختيار
              </Link>
            </div>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default SubProgramMuzakr;
