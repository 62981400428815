import React from "react";
import SingleProgram from "../SignleProgram";
import { EditIcon, Headphone, UsersIcon, Video } from "../../icons";
import Slider from "react-slick";

import "./Options.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PriceItem from "../PricesSection/Price";
import { Grid } from "@mui/material";
const Options = () => {
	const programes = [
		{
			id: "1p",
			title: "البرنامج التخاطبي الناطق",
			subtitle: "لتعليم الطفل الكلام والنطق وتحسين اللغه.",
			icon: <Headphone />,
			color: "#ADD6E5",
		},
		{
			id: "2p",
			title: "جلسات اونلاين",
			subtitle: "لتحسين النطق والكلام عند الاطفال علي يد خبراء التخاطب ونطق..",
			icon: <UsersIcon />,
			color: "#aa4db9",
		},
		{
			id: "3p",
			title: "الكورسات",
			subtitle:
				"في التربيه الخاصه , التخاطب , النطق , التوحد ,و فرط الحركه وتشتت الانتباه..",
			icon: <Video />,
			color: "#f05165",
		},
		
	];
	var settings = {
		arrows: true,
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 4,
	};

	return (
		// <section className='options__program__Section'>
		//   <div className="container">
		//     <div className="options__program__content">
		//       {programes.map(program => (
		//         <SingleProgram
		//           key={program.id}
		//           title={program.title}
		//           icon={program.icon}
		//           subtitle={program.subtitle}
		//         />
		//       ))}
		//     </div>
		//   </div>
		// </section>
		<section className="options__program__Section" id="services">
			<div className="container">
				{/* <Slider {...settings}> */}
				<Grid spacing={1} container>
					{programes.map((program) => (
						<Grid xs={12} sm={6} md={4} lg={3} item>
							<PriceItem
								key={program.id}
								icon={program.icon}
								title={program.title}
								iconColor={program.color}
								buttonColor={program.color}
								isRights={false}
								subtitle={program.subtitle}
							/>
						</Grid>
						// <SingleProgram
						// 	key={program.id}
						// 	title={program.title}
						// 	icon={program.icon}
						// 	subtitle={program.subtitle}
						// 	bgColor={program.color}
						// />
					))}
					{/* </Slider> */}
				</Grid>
			</div>
		</section>
	);
};

export default Options;
