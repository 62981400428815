import axiosRequest from "../../../../helpers/axiosRequest";
import env from "../../../../helpers/env";
const API_URL = env.baseURL;
const main = (url) => {
  return axiosRequest.get(API_URL + url);
};
const define = (url) => {
  return axiosRequest.get(API_URL + url);
};
const defineShow = (url, id) => {
  return axiosRequest.get(API_URL + url + "/" + id);
};
const choose = (url, id) => {
  return axiosRequest.get(API_URL + url + "/" + id);
};
const questions = (url, id) => {
  return axiosRequest.get(API_URL + url + "/" + id);
};
const preposServices = {
  main,
  define,
  defineShow,
  choose,
  questions,
};

export default preposServices;
