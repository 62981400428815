import { Star } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";

const ReviewStarRating = ({ count, ...props }) => {
	return (
		<Box
			display="flex"
			gap={0.3}
			justifyContent="end"
			alignItems="center"
			{...props}
		>
			<Star fontSize="small" style={{ color: "var(--yellow)" }} />
			<small>({count})</small>
		</Box>
	);
};

export default ReviewStarRating;
