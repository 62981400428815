import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import "./useVerbs.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Grid, Button } from "@mui/material";
import Carousel from "../../../../../components/Carousel";
import { Item } from "../../../../../components/Carousel/styles";
import VerbsService from "../../../../../redux/services/categories/verbs/programs";
import { RiContactsBook2Fill } from "react-icons/ri";
const VerbsUse = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { page, id } = useParams();
  const [nextBack, setNextBack] = useState({
    next: null,
    back: null,
  });
  const [images, setImages] = useState([]);
  const [masterImage, setMasterImage] = useState(null);

  const [sound, setSound] = useState({
    choose: null,
    right: null,
    wrong: null,
    rightChooseSound: null,
  });
  const [video, setVideo] = useState(null);
  const [playVideo, setPlayVideo] = useState(false);
  useEffect(() => {
    VerbsService.t3mem("verbuse", id).then((res) => {
      //console.log(res.data);
      setSound({
        choose: `https://maharttafl.com/sound/${res.data.sound.choose}`,
        item: `https://maharttafl.com/sound/${res.data.chooses.sound}`,
        rightChooseSound: `https://maharttafl.com/sound/${res.data.chooses.rightsound}`,
        right: `https://maharttafl.com/sound/${res.data.sound.right}`,
        wrong: `https://maharttafl.com/sound/${res.data.sound.wrong}`,
      });
      setVideo(res.data.chooses.video);
      setImages(res.data.chooses.images);
      setMasterImage(res.data.chooses.right1);
      setNextBack({ ...nextBack, next: res.data.next });
      setNextBack({ ...nextBack, back: res.data.previous });
      test(`https://maharttafl.com/sound/${res.data.chooses.sound}`);

      setIsLoading(false);
    });
  }, []);

  const test = async (sound) => {
    let audio = new Audio(sound);
    audio.play();
  };
  const chooseAudio = async () => {
    let audio = new Audio(sound.choose);
    await audio.play();
  };
  const chooseAudioItem = async () => {
    let audio2 = new Audio(sound.item);
    audio2.play();
  };
  const clapp = () => {
    let audio = new Audio(`https://maharttafl.com/sound/clapp.mp3`);
    audio.play();
  };
  if (isLoading) return <></>;

  return (
    <Grid
      container
      textAlign="center"
      justifyContent="center"
      padding={3}
      borderRadius={25}
    >
      <Grid item xs={12} style={{ backgroundColor: "white" }}>
        <Button
          variant="contained"
          color="secondary"
          style={{ width: "100%" }}
          onClick={() => {
            chooseAudio();
            setTimeout(() => {
              chooseAudioItem();
            }, 1300);
          }}
        >
          Listen
        </Button>
      </Grid>
      <Grid item xs={12} style={{ backgroundColor: "white" }}>
        <img src={`https://maharttafl.com/images/${masterImage}`} alt="" />
      </Grid>
      {playVideo ? (
        <Grid item xs={12} style={{ backgroundColor: "white" }}>
          <video
            autoPlay={false}
            controls
            playing={false}
            preload="auto"
            style={{ maxWidth: "50%", height: "auto" }}
            src={`https://maharttafl.com/images/${video}`}
          ></video>
        </Grid>
      ) : (
        <></>
      )}

      <Grid item xs={12} style={{ backgroundColor: "white" }}>
        <Carousel className="games__section container" keys={false}>
          {images.map((t, index) => {
            return (
              <Item>
                <img
                  src={`https://maharttafl.com/images/${t.image}`}
                  alt=""
                  style={{
                    width: "150px",
                    height: "150px",
                    border: "1px solid #dedede",
                    borderRadius: "30%",
                  }}
                  onClick={() => {
                    if (t.type === "wrong") {
                      let audio = new Audio(sound.wrong);
                      audio.play();
                    }
                    if (t.type === "right") {
                      clapp();
                      setTimeout(() => {
                        let audio = new Audio(sound.right);
                        audio.play();
                      }, 2500);
                      setPlayVideo(true);
                      setTimeout(() => {
                        let audio = new Audio(sound.rightChooseSound);
                        audio.play();
                      }, 3500);
                    }
                  }}
                />
              </Item>
            );
          })}
        </Carousel>
      </Grid>
    </Grid>
  );
};

export default VerbsUse;
