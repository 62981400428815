import React, { useState } from "react";
import Card from "../../../UI/Card";
import { Typography, Box, Avatar } from "@mui/material";
import { PlayCircle } from "@mui/icons-material";
import "./ReviewItem.css";

import TeacherReviewDetailsModal from "../ReviewDetailsModal";
import ReviewStarRating from "../ReviewRating";
import { ArrowBack, Close } from "@mui/icons-material";
import {Button} from "@mui/material";
import { useNavigate } from "react-router-dom";
export const TeacherReviewItem = ({ review }) => {
	const navigate = useNavigate();
	const [detailsOpen, setDetailsOpen] = useState(false);
	const onCloseDetails = () => setDetailsOpen(false);
	const onClick = () => navigate(`/teachers/${review.id}`);
	return (
		<Card className="review-teacher-item">
			<div onClick={() => setDetailsOpen(true)} className="wrapper">
				<div className="playvide-img">
					<img src={`https://maharttafl.com/images/${review.avatar}`} />
					<div className="overlay" />
					<Avatar>
						<PlayCircle />
					</Avatar>
				</div>
				<Box display="flex" alignItems="start" gap={0.5} mb={0.6}>
					<Typography variant="h6" className="title">
						{review.name}
					</Typography>
					<ReviewStarRating
						className="rating-review"
						mt={0.3}
						count={review.average_rating}
					/>
				</Box>
				<Typography className="country" variant="body2">
					{review.about}
				</Typography>
				<Box display="flex" alignItems="start" gap={0.5} mb={0.6}>
				<Button
							className="start-link"
							style={{color:'white',background:'#ADD6E5'}}
							endIcon={<ArrowBack />}
							onClick={onClick}
						>
							احجز جلستك المجانية الان
						</Button>
				</Box>
			</div>
			<TeacherReviewDetailsModal onClose={onCloseDetails} open={detailsOpen} details={review} />
		</Card>
	);
};
