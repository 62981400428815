import React, { useState } from "react";
import { Instagram, YouTube, Facebook } from "../../icons";
import { SiWhatsapp } from "react-icons/si";
import LoginPopup from "../LoginPoup";
import { useSelector, useDispatch } from "react-redux";
// import { login } from '../../redux/auth/authSlice'
import { Link, useNavigate } from "react-router-dom";
import UserHeaderCard from "../userHeeaderCard";
import { MdMenu } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";

import "./Header.css";
import HeaderTop from "./HeaderTop";

const Header = () => {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [signIn, setSignIn] = useState(true);
	const [openDropdown, setOpendropdown] = useState(false);
	const dispatch = useDispatch();
	const loggedIn = useSelector((state) => state.auth.isLoggedIn);
	// const loggedIn = false;
	// //console.log(loggedIn);

	return (
		<>
			<nav className="main__header">
				<HeaderTop isDiscount={true} />
				<div className="header__bottom container">
					<div className="header__bottom__rigth">
						<Link to="/">
							<img
								src="/logo.png"
								alt="Mahara"
								style={{ width: "150px", height: "80px" }}
							/>
						</Link>
					</div>
					<button
						className="toggle__men"
						onClick={() => setOpendropdown((prev) => !prev)}
					>
						{!openDropdown ? (
							<MdMenu className="toggle__icon" />
						) : (
							<AiOutlineClose className="toggle__icon" />
						)}
					</button>
					<div
						className={`header__bottom__center
            ${loggedIn ? "logged__in" : ""}
            ${openDropdown ? "open" : ""}`}
					>
								<>
								<a href="#about" className="header__links">
									من نحن
								</a>
								<a href="#services" className="header__links">
									الخدمات
								</a>
								<a href="#prices" className="header__links">
									الباقات
								</a>
								<div className="rigth__nav_mob">
									<div>
										<a
											href="https://www.instagram.com/maharttafl/"
											rel="noreferrer"
											target="_blank"
										>
											<Instagram className="header__icon" />
										</a>
										<a
											href="https://www.youtube.com/@user-di1xy5rm5s/"
											rel="noreferrer"
											target="_blank"
										>
											<YouTube className="header__icon" />
										</a>
										<a
											href="https://www.facebook.com/maharttafll"
											rel="noreferrer"
											target="_blank"
										>
											<Facebook className="header__icon" />
										</a>
									</div>
									<p className="header__parag">
										تابعنا علي صفحات التواصل الاجتماعي
									</p>
								</div>
								<div className="left__nav__mob">
									<p className="header__parag">تواصل معنا</p>
									<SiWhatsapp />
									<p className="header__number">+966583617232</p>
								</div>
							</>
						{/* {!loggedIn ? (
							<>
								<a href="#" className="header__links">
									من نحن
								</a>
								<a href="#" className="header__links">
									الخدمات
								</a>
								<a href="#" className="header__links">
									الباقات
								</a>
								<div className="rigth__nav_mob">
									<div>
										<a
											href="https://www.instagram.com/maharttafl/"
											rel="noreferrer"
											target="_blank"
										>
											<Instagram className="header__icon" />
										</a>
										<a
											href="https://www.youtube.com/@user-di1xy5rm5s/"
											rel="noreferrer"
											target="_blank"
										>
											<YouTube className="header__icon" />
										</a>
										<a
											href="https://www.facebook.com/maharttafll"
											rel="noreferrer"
											target="_blank"
										>
											<Facebook className="header__icon" />
										</a>
									</div>
									<p className="header__parag">
										تابعنا علي صفحات التواصل الاجتماعي
									</p>
								</div>
								<div className="left__nav__mob">
									<p className="header__parag">تواصل معنا</p>
									<SiWhatsapp />
									<p className="header__number">+966583617232</p>
								</div>
							</>
						) : (
							<>
								<Link to="/programs" className="header__link__page">
									<span className="bullet"></span>
									البرنامج التخاطبي الناطق
								</Link>
								<Link to="/zakra" className="header__link__page">
									<span className="bullet"></span>
									مهارات وذاكرة
								</Link>
								<Link to="/kraa" className="header__link__page">
									<span className="bullet"></span>
									مهارات القرائة
								</Link>
								<Link to="/exams" className="header__link__page">
									<span className="bullet"></span>
									اختبارات الحصيله اللغويه
								</Link>
								<div className="rigth__nav_mob">
									<div>
										<a
											href="https://www.instagram.com/maharttafl/"
											rel="noreferrer"
											target="_blank"
										>
											<Instagram className="header__icon" />
										</a>
										<a
											href="https://www.youtube.com/@user-di1xy5rm5s/"
											rel="noreferrer"
											target="_blank"
										>
											<YouTube className="header__icon" />
										</a>
										<a
											href="https://www.facebook.com/maharttafll"
											rel="noreferrer"
											target="_blank"
										>
											<Facebook className="header__icon" />
										</a>
									</div>
									<p className="header__parag">
										تابعنا علي صفحات التواصل الاجتماعي
									</p>
								</div>
								<div className="left__nav__mob">
									<p className="header__parag">تواصل معنا</p>
									<SiWhatsapp />
									<p className="header__number">+966583617232</p>
								</div>
							</>
						)} */}
					</div>
					<div className="header__bottom__rigth">
						<div className="header__bottom__btns">
							{!loggedIn ? (
								<>
									<button
										className="log__btn"
										onClick={() => {
											navigate("/login");
											// setOpen((prev) => !prev);
											// setSignIn(true);
											// dispatch(login());
										}}
									>
										دخول
									</button>
									<button
										className="subsctibe__btn"
										onClick={() => {
											navigate("/signup")
											// setOpen((prev) => !prev);
											// setSignIn(false);
											// dispatch(login());
										}}
									>
										اشترك الان
									</button>
								</>
							) : (
								<UserHeaderCard />
							)}
						</div>
					</div>
				</div>
			</nav>
			{open && <LoginPopup setSignIn={setSignIn} signIn={signIn} />}
		</>
	);
};

export default Header;
