/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Facebook, Instagram, YouTube } from "../../icons";
import { SiWhatsapp } from "react-icons/si";

import "./Footer.css";
import HeaderTop from "../Header/HeaderTop";

const Footer = () => {
	return (
		<div className="main__header footer">
			<div className="container">
				<HeaderTop />
			</div>
		</div>
	);
};

export default Footer;
