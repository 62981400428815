import axiosRequest from "../../../../helpers/axiosRequest";
import env from "../../../../helpers/env";
const API_URL = env.baseURL;

const category = () => {
  return axiosRequest.get(API_URL + "category");
};

const categoryService = {
  category,
};

export default categoryService;
