import React,{useState,useEffect} from "react";
import { Container } from "react-bootstrap";
import Title from "../Title";
import ClientReviewItem from "./Review";
import { Grid } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./ClientsReview.css";
import axiosRequest from '../../helpers/axiosRequest';

const BREAKPOINTS = {
	0: { slidesPerView: 1 },
	640: { slidesPerView: 2 },
	768: { slidesPerView: 3 },
};

const REVIEWS = [
	{
		id: 1,
		name: "عمرو محمد",
		rating: 3,
		img: "https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D&w=1000&q=80",
		body: "اجمل موقع الحقيقه هوا ممتاز جدا ويستحق كل التقدير والعمل عليه ",
	},
	{
		id: 2,
		name: "عمرو محمد",
		rating: 3.5,
		img: "https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D&w=1000&q=80",
		body: "اجمل موقع الحقيقه هوا ممتاز جدا ويستحق كل التقدير والعمل عليه ",
	},
	{
		id: 3,
		name: "عمرو محمد",
		rating: 4,
		img: "https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D&w=1000&q=80",
		body: "اجمل موقع الحقيقه هوا ممتاز جدا ويستحق كل التقدير والعمل عليه ",
	},
];

const ClientsReview = () => {
	const [isLoading , setIsLoading] = useState(true);
	const [testimonial , setTestimonial] = useState([]);
	useEffect(() => {
	  axiosRequest.get('testimonial').then(res=>{
		console.log(res);
		setTestimonial(res.data.data);
	  }).catch(err=>{console.log(err)})
	}, [])
	
	return (
		<Container>
			<Title title="عملاءنا" align="center" />
			{/* <Grid container spacing={2}> */}
			<Swiper breakpoints={BREAKPOINTS} slidesPerView={3} spaceBetween={20}>
				{testimonial.map((review) => (
					<SwiperSlide key={review.id} className="client-review-slide">
						<ClientReviewItem
							body={review.body}
							key={review.id}
							img={`https://maharttafl.com/images/${review.avatar}`}
							rating={review.rating}
							name={review.name}
						/>
					</SwiperSlide>
				))}
			</Swiper>
			{/* </Grid> */}
		</Container>
	);
};

export default ClientsReview;
