import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import "./Choose.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import Carousel from "../../../../../components/Carousel";
import { Item } from "../../../../../components/Carousel/styles";

import negationServices from "../../../../../redux/services/categories/negation/programs";
const NegationChoose = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id, page } = useParams();
  const [nextBack, setNextBack] = useState({
    next: null,
    back: null,
  });
  const [images, setImages] = useState([]);

  const [sound, setSound] = useState({
    choose: null,
    right: null,
    wrong: null,
  });
  useEffect(() => {
    negationServices.choose(page, id).then((res) => {
      setSound({
        choose: `https://maharttafl.com/sound/${res.data.sound.choose}`,
        item: `https://maharttafl.com/sound/${res.data.opposites.sound}`,
        right: `https://maharttafl.com/sound/${res.data.sound.right}`,
        wrong: `https://maharttafl.com/sound/${res.data.sound.wrong}`,
      });
      // //console.log(res.data);
      setImages(res.data.opposites.images);
      setNextBack({ ...nextBack, next: res.data.next });
      setNextBack({ ...nextBack, back: res.data.previous });
      setIsLoading(false);
    });
  }, [isLoading]);
  const chooseAudio = async () => {
    let audio = new Audio(sound.choose);
    await audio.play();
  };
  const chooseAudioItem = async () => {
    let audio2 = new Audio(sound.item);
    audio2.play();
  };
  const clapp = () => {
    let audio = new Audio(`https://maharttafl.com/sound/clapp.mp3`);
    audio.play();
  };
  if (isLoading) return <></>;

  return (
    <div className="games__page">
      <div>
        <button
          style={{ width: "100%" }}
          onClick={() => {
            chooseAudio();
            setTimeout(() => {
              chooseAudioItem();
            }, 1300);
          }}
        >
          Listen
        </button>
        <Carousel className="games__section container" keys={false}>
          {images.map((t, index) => {
            return (
              <Item>
                <img
                  src={`https://maharttafl.com/images/${t.image}`}
                  alt=""
                  style={{
                    width: "150px",
                    height: "150px",
                    border: "1px solid #dedede",
                    borderRadius: "30%",
                  }}
                  onClick={() => {
                    if (t.type === "wrong") {
                      let audio = new Audio(sound.wrong);
                      audio.play();
                    }
                    if (t.type === "right") {
                      clapp();
                      setTimeout(() => {
                        let audio = new Audio(sound.right);
                        audio.play();
                      }, 2500);
                    }
                  }}
                />
              </Item>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default NegationChoose;
