import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import "./Choose.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Grid } from "@mui/material";
import voiceServices from "../../../../../redux/services/categories/tafdil/programs";

const VoiceDefineGame = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id, page } = useParams();
  const [nextBack, setNextBack] = useState({
    next: null,
    back: null,
  });
  const [images, setImages] = useState([]);

  const [sound, setSound] = useState(null);
  useEffect(() => {
    voiceServices.define("voicedefine").then((res) => {
      // setSound({
      //   choose: `https://maharttafl.com/sound/${res.data.sound.choose}`,
      //   item: `https://maharttafl.com/sound/${res.data.opposites.sound}`,
      //   right: `https://maharttafl.com/sound/${res.data.sound.right}`,
      //   wrong: `https://maharttafl.com/sound/${res.data.sound.wrong}`,
      // });
      // setSound(`https://maharttafl.com/sound/${res.data.opposites.sound}`);
      // //console.log(res.data);
      // setImages({
      //   avatar1: res.data.opposites.avatar1,
      //   avatar2: res.data.opposites.avatar2,
      // });
      setImages(res.data.opposites);
      setNextBack({ ...nextBack, next: res.data.next });
      setNextBack({ ...nextBack, back: res.data.previous });
      setIsLoading(false);
      // let audio = new Audio(res.data.opposites.sound);
      // audio.play();
    });
  }, []);
  const playAudio = async (s) => {
    
    let audio = new Audio(s);
    await audio.play();
  };

  if (isLoading) return <></>;

  return (
    <div style={{ padding: 10 }}>
      <Grid
        container
        className=" container"
        justifyContent="center"
        textAlign="center"
        style={{ padding: 10, width: "100%", background: "white" }}
      >
        {images.map((e, i) => {
          return (
            <Grid item xs={3} key={i}>
              <img
                src={`https://maharttafl.com/images/${e.avatar}`}
                alt=""
                style={{
                  width: "150px",
                  height: "150px",
                  border: "1px solid #dedede",
                  borderRadius: "30%",
                }}
                onClick={() => {
                  playAudio(`https://maharttafl.com/sound/${e.sound}`);
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default VoiceDefineGame;
