import {
	Avatar,
	Box,
	CardContent,
	CardHeader,
	Grid,
	Rating,
	Typography,
} from "@mui/material";
import React from "react";
import Card from "../../../UI/Card";
import ReviewStarRating from "../../TeacherReview/ReviewRating";
import { FormatQuoteRounded } from "@mui/icons-material";
import "./Review.css";

const ClientReviewItem = ({ img, rating, body, name }) => {
	return (
		<Card className="client-item-review-wrap">
			<Avatar className="client-img" src={img} />
			<Box
				justifyContent={"center"}
				gap={0.2}
				my={0.5}
				display={"flex"}
				alignItems={"center"}
			>
				<Rating readOnly value={Number(rating)} size="small" />
				<small>({rating})</small>
			</Box>
			<CardContent>
				<Typography variant="subtitle1" className="client-review">
					<FormatQuoteRounded />
					{body}
					<FormatQuoteRounded />
				</Typography>
			</CardContent>
			<Typography className="client-name" color="GrayText">
				{name}
			</Typography>
		</Card>
	);
};

export default ClientReviewItem;
