import React, { useState, useEffect } from "react";

import "./SubProgramMufrad.css";
import Card from "../../../../../UI/Card";

import { Link, useLocation, useParams } from "react-router-dom";

import mufradServices from "../../../../../redux/services/categories/mufrad/programs";
const SubProgramMufrad = (props) => {
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { page } = useParams();
  const [category, setCategory] = useState({
    avatar: "",
    id: 1000,
    name: "التصنيف",
  });
  const [programsId, setProgramsId] = useState({
    choose: null,
    define: null,
  });
  const [subPage, setSubPage] = useState({
    choose: null,
    define: null,
  });
  useEffect(() => {
    mufradServices.main(page).then((res) => {
      setProgramsId(res.data);
    });
    if (page === "muthana") {
      setSubPage({
        define: "muthanadefine",
        choose: "muthanachoose",
      });
    }
    if (page === "mufrad") {
      setSubPage({
        define: "mufraddefine",
        choose: "mufradchoose",
      });
    }
    setIsLoading(false);
  }, []);
  if (isLoading) return <></>;

  return (
    <section className="prices__section">
      <div className="container" style={{ marginTop: 10 }}>
        <div className="prices__content">
          <Card radius="20px">
            <div className="single__program__box">
              <div className="program__icon">
                {/* <Headphone /> */}
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <div className="programe__title">{category.name}</div>

              <Link
                className="program__details__btn"
                to={`/programs/mufrad/${page}/${subPage.define}/define/${programsId.define}`}
                state={category}
              >
                التعريف
              </Link>
              <Link
                className="program__details__btn"
                to={`/programs/mufrad/${page}/${subPage.choose}/choose/${programsId.choose}`}
                state={category}
              >
                الاختيار
              </Link>
            </div>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default SubProgramMufrad;
