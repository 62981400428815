import axiosRequest from "../../../../helpers/axiosRequest";
import env from "../../../../helpers/env";
const API_URL = env.baseURL;
const subProgram = (url, id) => {
  return axiosRequest.get(API_URL + url + "/" + id);
};
const magmoaaPhoto = (url, page, id) => {
  return axiosRequest.get(API_URL + url + "/" + page + "/" + id);
};

const photo = () => {
  return axiosRequest.get(API_URL + "photo");
};

const ProgramsSerivce = {
  subProgram,
  magmoaaPhoto,
  photo,
};

export default ProgramsSerivce;
