import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import ProgramsSerivce from "../../../../redux/services/categories/Programs/programs";
import "./Define.css";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader

import { Carousel } from "react-responsive-carousel";
import { Grid } from "@mui/material";
// import Carousel from "../../../../components/Carousel";
// import { Item } from "../../../../components/Carousel/styles";
const Define = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { page, id } = useParams();
  const [magmoaa, setMagmoaa] = useState({});
  const [images, setImages] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sound, setSound] = useState("");
  useEffect(() => {
    ProgramsSerivce.magmoaaPhoto("magmoaa", page, id).then((res) => {
      setMagmoaa(res.data);
      //console.log(res.data);
      setSound(`https://maharttafl.com/sound/${res.data.content.sound}`);
      setImages(res.data.content.photo.split(","));
      setIsLoading(false);
      // setImages([...images, res.data.content.photo.split(",")]);
    });
  }, [isLoading]);
  if (isLoading) return <></>;
  return (

        <Carousel autoPlay interval="500000" transitionTime="5000000" showThumbs={false}  infiniteLoop>
          {images.map((t, index) => {
            return (
              <div key={index}>
                <img
                  src={`https://maharttafl.com/images/${t}`}
                  alt=""
                  onClick={() => {
                    setCurrentSlide(index);
                    let audio = new Audio(sound);
                    audio.play();
                  }}
                />
              </div>
            );
          })}
        </Carousel>

      
  );
};

export default Define;
