import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import "./Choose.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import Carousel from "../../../../../components/Carousel";
import { Item } from "../../../../../components/Carousel/styles";

import negationServices from "../../../../../redux/services/categories/negation/programs";
import conjunctionServices from "../../../../../redux/services/categories/conjunction/programs";
const ConjunctionGamePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id, page } = useParams();
  const [nextBack, setNextBack] = useState({
    next: null,
    back: null,
  });
  const [images, setImages] = useState(null);

  const [sound, setSound] = useState(null);
  useEffect(() => {
    conjunctionServices.game(page, id).then((res) => {
      // setSound({
      //   choose: `https://maharttafl.com/sound/${res.data.sound.choose}`,
      //   item: `https://maharttafl.com/sound/${res.data.opposites.sound}`,
      //   right: `https://maharttafl.com/sound/${res.data.sound.right}`,
      //   wrong: `https://maharttafl.com/sound/${res.data.sound.wrong}`,
      // });
      setSound(`https://maharttafl.com/sound/${res.data.define.sound}`);
      // //console.log(res.data);
      setImages(res.data.define.avatar);
      setNextBack({ ...nextBack, next: res.data.next });
      setNextBack({ ...nextBack, back: res.data.previous });
      setIsLoading(false);
    });
  }, []);
  const playAudio = async () => {
    let audio = new Audio(sound);
    await audio.play();
  };

  if (isLoading) return <></>;

  return (
    <div className="games__page">
      <div>
        <button
          style={{ width: "100%" }}
          onClick={() => {
            playAudio();
          }}
        >
          Listen
        </button>
        <Carousel className="games__section container" keys={false}>
          <Item>
            <img
              src={`https://maharttafl.com/images/${images}`}
              alt=""
              style={{
                width: "150px",
                height: "150px",
                border: "1px solid #dedede",
                borderRadius: "30%",
              }}
              onClick={() => {
                playAudio();
              }}
            />
          </Item>
        </Carousel>
      </div>
    </div>
  );
};

export default ConjunctionGamePage;
