import React, { useState, useEffect, Fragment } from "react";
import { Link, useParams } from "react-router-dom";

// import Card from "../../UI/Card";
import { Card } from "@mui/material";

import "./verbsDefine.css";
import ProgramsSerivce from "../../../../../redux/services/categories/Programs/programs";
import VerbsService from "../../../../../redux/services/categories/verbs/programs";

const SubProgramVerbsChoose = () => {
  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const sub = VerbsService.choose("verbchoose").then((res) => {
      setCategory(res.data.defines);
      
      //console.log(res.data.defines);
    });
  }, [isLoading]);

  useEffect(() => {}, [isLoading]);

  return (
    <section className="learning__program__secttion">
      <Card>
        <div className="learning__container">
          {category.map((t) => {
            // //console.log(t);
            // return <></>;
            return (
              <Fragment key={t.id}>
                <Link
                  className="program__details__btn"
                  to={`/programs/verbs/choose/${t.id}/${t.choose[0].id}`}
                >
                  {t.name}
                </Link>
              </Fragment>
            );
          })}
        </div>
      </Card>
    </section>
  );
};

export default SubProgramVerbsChoose;
