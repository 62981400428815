import axiosRequest from "../../../../helpers/axiosRequest";
import env from "../../../../helpers/env";
const API_URL = env.baseURL;
const main = (page, url) => {
  return axiosRequest.get(API_URL + page + "/" + url);
};

const partOfAllServices = {
  main,
};

export default partOfAllServices;
