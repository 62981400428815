import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import "./preposQuestions.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Grid } from "@mui/material";
import Carousel from "../../../../../components/Carousel";
import { Item } from "../../../../../components/Carousel/styles";
import preposServices from "../../../../../redux/services/categories/prepos/programs";
import DefaultAudio from "../../../../../helpers/defaultAudio";
const PreposQuestions = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { page, id } = useParams();
  const [nextBack, setNextBack] = useState({
    next: null,
    back: null,
  });
  // const [images, setImages] = useState([]);
  const [masterImage, setMasterImage] = useState(null);

  const [sound, setSound] = useState({
    question: null,
    answer: null,
  
  });
  const [images, setImages] = useState({
    right1: null,
    right2: null,
    wrong1: null,
    wrong2: null,
  });
  useEffect(() => {
    preposServices.questions("prepostest", id).then((res) => {
      setSound({
        question: `https://maharttafl.com/sound/${res.data.chooses.question}`,
        answer: `https://maharttafl.com/sound/${res.data.chooses.answer}`,
      });
      //console.log(res.data);
      setImages({
        right1: `https://maharttafl.com/images/${res.data.chooses.right1}`,
        right2: `https://maharttafl.com/images/${res.data.chooses.right2}`,
        wrong1: `https://maharttafl.com/images/${res.data.chooses.wrong1}`,
        wrong2: `https://maharttafl.com/images/${res.data.chooses.wrong2}`,
      });
      // setMasterImage(res.data.chooses.right1);
      // setNextBack({ ...nextBack, next: res.data.next });
      // setNextBack({ ...nextBack, back: res.data.previous });
      setIsLoading(false);
    });
  }, [isLoading]);
  const chooseAudio = async () => {
    let audio = new Audio(sound.question);
    await audio.play();
  };
  const chooseAudioItem = async () => {
    // let audio2 = new Audio(sound.item);
    // audio2.play();
  };
  const clapp = () => {
    let audio = new Audio(`https://maharttafl.com/sound/clapp.mp3`);
    audio.play();
  };
  if (isLoading) return <></>;

  return (
    <div className="games__page">
      <button
        style={{ width: "100%" }}
        onClick={() => {
          chooseAudio();
          setTimeout(() => {
            chooseAudioItem();
          }, 1300);
        }}
      >
        Listen
      </button>
      <section className="games__section container">
        <img src={images.right1} alt="" />
      </section>
      <div>
        <Carousel className="games__section container" keys={false}>
          <Item>
                <img
                  src={images.wrong2}
                  alt=""
                  style={{
                    width: "150px",
                    height: "150px",
                    border: "1px solid #dedede",
                    borderRadius: "30%",
                  }}
                  onClick={() => {
                    DefaultAudio.wrong();
                  }}
                />
            <img
              src={images.right2}
              alt=""
              style={{
                width: "150px",
                height: "150px",
                border: "1px solid #dedede",
                borderRadius: "30%",
              }}
              onClick={() => {
                clapp();
                setTimeout(() => {
                  let audio = new Audio(sound.answer);
                  audio.play();
                }, 2500);
              }}
            />
            <img
              src={images.wrong1}
              alt=""
              style={{
                width: "150px",
                height: "150px",
                border: "1px solid #dedede",
                borderRadius: "30%",
              }}
              onClick={() => {
                DefaultAudio.wrong();
              }}
            />
          </Item>
        </Carousel>
      </div>
    </div>
  );
};

export default PreposQuestions;
