import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Grid, Typography } from "@mui/material";
import { isRejected } from "@reduxjs/toolkit";
import OppositeServices from "../../../../../redux/services/categories/opposite/programs";
import mufradServices from "../../../../../redux/services/categories/mufrad/programs";
const MufradDefine = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id, subPage, page } = useParams();
  // const [images, setImages] = useState([]);
  const [oppositeName, setOppositeName] = useState("");
  const [selectedOpposite, setSelectedOpposite] = useState(null);
  const [images, setImages] = useState({
    first: null,
    second: null,
  });
  useEffect(() => {
    mufradServices.define(subPage, id).then((res) => {
      if (subPage === "muthanadefine") {
        setImages({
          first: {
            image: res.data.opposites.singles,
            sound: res.data.opposites.single,
          },
          second: {
            image: res.data.opposites.muthanas,
            sound: res.data.opposites.muthana,
          },
        });
      }
      if (subPage === "mufraddefine") {
        setImages({
          first: {
            image: res.data.opposites.single,
            sound: res.data.opposites.mufrad,
          },
          second: {
            image: res.data.opposites.more,
            sound: res.data.opposites.muthana,
          },
        });
      }
      setIsLoading(false);
    });
  }, []);
  const playAudio = (a) => {
    let audio = new Audio(`https://maharttafl.com/sound/${a}`);
    audio.play();
  };
  if (isLoading) return <></>;
  return (
    <Grid
      container
      alignContent="center"
      justifyContent="center"
      textAlign="center"
      padding={4}
      marginTop={4}
      marginBottom={4}
      style={{ background: "white" }}
    >
      <Grid item xs={12}>
        <Typography variant="h4"> {oppositeName}</Typography>
      </Grid>
      {selectedOpposite ? (
        <Grid item xs={12}>
          <img
            src={`https://maharttafl.com/images/${selectedOpposite.image}`}
            alt=""
            style={{
              width: "150px",
              height: "150px",
              border: "1px solid #dedede",
              borderRadius: "30%",
            }}
            onClick={() => {
              playAudio(selectedOpposite.sound);
            }}
          />
        </Grid>
      ) : (
        <></>
      )}
      <Grid item xs={6}>
        <img
          src={`https://maharttafl.com/images/${images.first.image}`}
          alt=""
          style={{
            width: "150px",
            height: "150px",
            border: "1px solid #dedede",
            borderRadius: "30%",
          }}
          onClick={() => {
            setSelectedOpposite(images.first);
            playAudio(images.first.sound);
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <img
          src={`https://maharttafl.com/images/${images.second.image}`}
          alt=""
          style={{
            width: "150px",
            height: "150px",
            border: "1px solid #dedede",
            borderRadius: "30%",
          }}
          onClick={() => {
            setSelectedOpposite(images.second);
            playAudio(images.second.sound);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default MufradDefine;
