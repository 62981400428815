import axiosRequest from "../../../../helpers/axiosRequest";
import env from "../../../../helpers/env";
const API_URL = env.baseURL;

const Things = (url) => {
  return axiosRequest.get(API_URL + url);
};

const ThingsServices = {
  Things,
};

export default ThingsServices;
