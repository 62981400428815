import React from "react";
import "./card.css";

const Card = ({ className, children, radius }) => {
	return (
		<div
			className={`card ${className || ""}`}
			style={{ borderRadius: radius || "10px" }}
		>
			{children}
		</div>
	);
};

export default Card;
