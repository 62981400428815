import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

import "./Choosing.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Grid } from "@mui/material";

import examsServices from "../../../../redux/services/exams/programs";
const ExamsShow = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();
  const [next, setNext] = useState(null);
  const [question, setQuestion] = useState(null);
  const [currentExam, setCurrentExam] = useState(null);
  useEffect(() => {
    examsServices.show("questionanswer", id).then((res) => {
      // //console.log(cuurentExam, "t");
      // setImages(res.data.question.answer);
      setQuestion(res.data.question);
      setNext(res.data.next);
      setCurrentExam(res.data.userexam);
      // setNextBack({ ...nextBack, next: res.data.next });

      setIsLoading(false);
    });
  }, []);
  if (isLoading) return <></>;

  return (
    <div className="games__page">
      <div>
        <Grid
          container
          className="games__section container"
          justifyContent="center"
          textAlign="center"
        >
          {question.answer.map((e, index) => {
            return (
              <Grid item xs={3} className="letterBox">
                <img
                  alt=""
                  src={`https://maharttafl.com/images/${e.answer}`}
                  style={{
                    width: "150px",
                    height: "150px",
                    border: "1px solid #dedede",
                    borderRadius: "30%",
                  }}
                  onClick={() => {
                    examsServices
                      .chooseAnswer("questionanswer", id, {
                        question_id: question.id,
                        answer: e.id,
                        cuurentExam: currentExam.id,
                        next: next,
                      })
                      .then((res) => {
                        if (res.data.next) {
                          setQuestion(res.data.question);
                          setNext(res.data.next);
                        } else {
                          navigate("/exams");
                        }
                      });
                  }}
                />
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <button
              style={{ width: "100%" }}
              onClick={() => {
                examsServices
                  .endTest("endtest", `cuurentExam=${currentExam.id}`)
                  .then((res) => {
                    //console.log(res.data);
                    navigate("/exams/end", {
                      state: res.data,
                    });
                  });
              }}
            >
              End Test
            </button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ExamsShow;
