import React from "react";
import AboutSection from "../../components/AboutSection";
import MainHero from "../../components/MainHero";
import Options from "../../components/Options";
import PricesSection from "../../components/PricesSection";
import ContactUs from "../../components/ContactUs";
import TeacherReviewsList from "../../components/TeacherReview/ReviewsList";
import ClientsReview from "../../components/ClientsReviews";

const Home = () => {
	return (
		<>
			<MainHero />
			<TeacherReviewsList />
			<Options />
			<AboutSection />
			<PricesSection />
			<ClientsReview />
			<ContactUs />
		</>
	);
};

export default Home;
