import {
	Avatar,
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	IconButton,
	Modal,
	Typography,
} from "@mui/material";
import "./ReviewDetailsModal.css";
import ReactPlayer from "react-player";
import { ArrowBack, Close } from "@mui/icons-material";
import ReviewStarRating from "../ReviewRating";
import { CardBody } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const TeacherReviewDetailsModal = ({ open, onClose, details }) => {
	const navigate = useNavigate();
	const onClick = () => navigate(`/teachers/${details.id}`);
	return (
		<Modal open={open} style={{ overflowY: "auto" }}>
			<Box className="teacher-review-item-modal">
				<IconButton onClick={onClose} className="close-button">
					<Close />
				</IconButton>
				<ReactPlayer
					width="100%"
					height="320px"
					controls
					url={`https://maharttafl.com/images/${details.miniVideo}`} // For Testing
				/>
				<Card elevation={0} sx={{ p: 0 }}>
					<CardHeader
						className="header"
						avatar={
							<Avatar src={`https://maharttafl.com/images/${details.avatar}`} />
						}
						title={<Typography variant="h6">{details.name}</Typography>}
						subheader={<Typography variant="body2">{details.about}</Typography>}
						action={<ReviewStarRating count={details.average_rating} />}
					/>
					<CardContent>
						<CardBody>
							<Typography className="description" variant="body1">
								{details.summary}
							</Typography>
						</CardBody>
						<Button
							className="start-link"
							endIcon={<ArrowBack />}
							onClick={onClick}
						>
							احجز جلستك المجانية الان
						</Button>
					</CardContent>
				</Card>
			</Box>
		</Modal>
	);
};

export default TeacherReviewDetailsModal;
