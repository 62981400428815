import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import "./AdvChoose.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Grid } from "@mui/material";
import Carousel from "../../../../../components/Carousel";
import { Item } from "../../../../../components/Carousel/styles";
import tasnifServices from "../../../../../redux/services/categories/tasnif/programs";
import DefaultAudio from "../../../../../helpers/defaultAudio";
const AdvChoose = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { page, id } = useParams();
  const [nextBack, setNextBack] = useState({
    next: null,
    back: null,
  });
  const [images, setImages] = useState([]);
  const [masterImage, setMasterImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [sound, setSound] = useState({
    choose: null,
    right: null,
    wrong: null,
  });
  useEffect(() => {
    tasnifServices.advChoose("tasnifadvance", id).then((res) => {
      setSound({
        choose: `https://maharttafl.com/sound/${res.data.sound.choose}`,
        item: `https://maharttafl.com/sound/${res.data.opposites.sound}`,
        right: `https://maharttafl.com/sound/${res.data.sound.right}`,
        wrong: `https://maharttafl.com/sound/${res.data.sound.wrong}`,
      });
      setMasterImage({
        image: `https://maharttafl.com/images/${res.data.opposites.right}`,
        type: "right",
      });
      setImages([
        {
          image: `https://maharttafl.com/images/${res.data.opposites.right1}`,
          type: "right",
        },
        {
          image: `https://maharttafl.com/images/${res.data.opposites.right2}`,
          type: "right",
        },
        {
          image: `https://maharttafl.com/images/${res.data.opposites.right3}`,
          type: "right",
        },
        {
          image: `https://maharttafl.com/images/${res.data.opposites.right4}`,
          type: "right",
        },
        {
          image: `https://maharttafl.com/images/${res.data.opposites.wrong1}`,
          type: "wrong",
        },
        {
          image: `https://maharttafl.com/images/${res.data.opposites.wrong2}`,
          type: "wrong",
        },
      ]);
      // //console.log(res.data);
      // setImages({ ...res.data.opposites.images });
      // setMasterImage(res.data.chooses.right1);
      // setNextBack({ ...nextBack, next: res.data.next });
      // setNextBack({ ...nextBack, back: res.data.previous });
      setIsLoading(false);
    });
  }, []);
  // //console.log(images)
  const chooseAudio = async () => {
    let audio = new Audio(sound.choose);
    await audio.play();
  };
  const chooseAudioItem = async () => {
    let audio2 = new Audio(sound.item);
    audio2.play();
  };
  const clapp = () => {
    let audio = new Audio(`https://maharttafl.com/sound/clapp.mp3`);
    audio.play();
  };
  if (isLoading) return <></>;

  return (
    <div className="games__page">
      <button
        style={{ width: "100%" }}
        onClick={() => {
          chooseAudio();
          setTimeout(() => {
            chooseAudioItem();
          }, 1300);
        }}
      >
        Listen
      </button>
      {selectedImages.length > 0 ? (
        <Carousel className="games__section container" keys={false}>
          {selectedImages.map((e, index) => {
            return (
              <Item key={index}>
                <img
                  src={e.image}
                  alt=""
                  style={{
                    width: "150px",
                    height: "150px",
                    border: "1px solid #dedede",
                    borderRadius: "30%",
                  }}
                  onClick={() => {
                    // if (e.type === "right") {
                    //   DefaultAudio.clapp();
                    //   setSelectedImages(images[index]);
                    //   setImages(images.filter((_, i) => i !== index));
                    //   // //console.log(images.splice(index,1));
                    // } else {
                    //   DefaultAudio.wrong();
                    // }
                  }}
                />
              </Item>
            );
          })}
        </Carousel>
      ) : (
        <></>
      )}
      <section className="games__section container">
        <img src={masterImage.image} alt="" />
      </section>
      <div>
        <Carousel className="games__section container" keys={false}>
          {images.map((e, index) => {
            return (
              <Item key={index}>
                <img
                  src={e.image}
                  alt=""
                  style={{
                    width: "150px",
                    height: "150px",
                    border: "1px solid #dedede",
                    borderRadius: "30%",
                  }}
                  onClick={() => {
                    if (e.type === "right") {
                      DefaultAudio.clapp();
                      setSelectedImages([...selectedImages, images[index]]);
                      setImages(images.filter((_, i) => i !== index));
                      // //console.log(images.splice(index,1));
                    } else {
                      DefaultAudio.wrong();
                    }
                  }}
                />
              </Item>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default AdvChoose;
