import React, { useState } from "react";

import Title from "../../../components/Title";
import { Headphone, UsersIcon, Video } from "../../../icons";

import "./MainCategory.css";
import Card from "../../../UI/Card";

import { Link, useLocation } from "react-router-dom";
const CategoryShow = (props) => {
  const { state } = useLocation();
  const [category, setCategory] = useState(state);

  return (
    <section className="prices__section">
      <div className="container" style={{ marginTop: 10 }}>
        <div className="prices__content">
          <Card radius="20px">
            <div className="single__program__box">
              <div className="program__icon">
                {/* <Headphone /> */}
                <img
                  src={`https://maharttafl.com/images/${category.avatar}`}
                  alt=""
                />
              </div>
              <div className="programe__title">{category.name}</div>

              {/* /programs/category/define/:name/:id */}
              <Link
                className="program__details__btn"
                to={`/programs/category/photo/photo/${category.id}`}
                state={category}
              >
                التعريف
              </Link>
              <Link
                className="program__details__btn"
                to={`/programs/category/choose/magchoosing/${category.id}`}
                state={category}
              >
                الاختيار
              </Link>
              <Link
                className="program__details__btn"
                to={`/programs/category/gallary/magchoose/${category.id}`}
                state={category}
              >
                تعميم
              </Link>
              <Link
                className="program__details__btn"
                to={`/programs/category/diff/magdiff/${category.id}`}
                state={category}
              >
                تطابق
              </Link>
              {category.dist ? (
                <Link
                  className="program__details__btn"
                  to={`/programs/category/dist/magtam/${category.id}/${category.dist.id}`}
                  state={category}
                >
                  التمييز العام
                </Link>
              ) : (
                <></>
              )}

              {/* 
              <Link className="program__details__btn">تعميم</Link>
              <Link className="program__details__btn">تطابق</Link>
              {category.page ? (
                <Link className="program__details__btn">التمييز العام</Link>
              ) : (
                <></>
              )} */}
            </div>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default CategoryShow;
