import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import ProgramsSerivce from "../../../../redux/services/categories/Programs/programs";
import "./Choosing.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Grid } from "@mui/material";
import Box from "../../../../components/Box";
import chooseDiffServices from "../../../../redux/services/kraa/chooseDiff/programs";
import DefaultAudio from "../../../../helpers/defaultAudio";
import tamiezSoty7rof from "../../../../redux/services/kraa/tamiezSoty7rof/programs";
const TamiezSoty7rof = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { page, id } = useParams();
  const [nextBack, setNextBack] = useState({
    next: null,
    back: null,
  });
  const [images, setImages] = useState([]);

  const [sound, setSound] = useState({
    choose: null,
    item: null,
    right: null,
    wrong: null,
  });
  useEffect(() => {
    tamiezSoty7rof.main(page, id).then((res) => {
      setSound({
        choose: `https://maharttafl.com/sound/${res.data.sound.choose}`,
        item: `https://maharttafl.com/sound/${res.data.tamiez.sound}`,
        right: `https://maharttafl.com/sound/${res.data.sound.right}`,
        wrong: `https://maharttafl.com/sound/${res.data.sound.wrong}`,
      });
      //console.log(res.data);
      setImages(res.data.tamiez.images);
      setNextBack({ ...nextBack, next: res.data.next });
      setNextBack({ ...nextBack, back: res.data.previous });
      setIsLoading(false);
    });
  }, []);
  if (isLoading) return <></>;
  const playAudio = (s) => {
    let audio = new Audio(s);
    audio.play();
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <button
          style={{ width: "100%" }}
          onClick={() => {
            playAudio(sound.choose);
            setTimeout(() => {
              playAudio(sound.item);
            }, 1500);
          }}
        >
          Listen
        </button>
      </Grid>
      {images.map((e, index) => {
        return (
          <Grid item xs={3} className="letterBox">
            <Box
              text={e.image}
              onClick={() => {
                if (e.type === "wrong") {
                  let audio = new Audio(sound.wrong);
                  audio.play();
                }
                if (e.type === "right") {
                  DefaultAudio.clapp();
                  setTimeout(() => {
                    playAudio(sound.right);
                  }, 2500);
                }
              }}
            ></Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default TamiezSoty7rof;
