import React, { useLayoutEffect } from "react";
import Header from "./components/Header";
import TestsPage from "./pages/TestsPage";
import CoursesPage from "./pages/CoursesPage";
import Teachers from "./pages/Teachers";
import EditProfilePage from "./pages/EditProfilePage";
import TakativeProgramPage from "./pages/TakativeProgramPage";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import "./styles.css";
// import GamesPage from "./pages/V2/Programs/Choosing";
import Login from "./pages/Auth";
import Signup from "./pages/Signup";
import { useSelector } from "react-redux";
import CategoryShow from "./pages/Programs/CategoryShow";
import SubProgram from "./pages/Programs/SubProgram";
// import Magmoaa from "./pages/V2/Programs/Choosing";
import Define from "./pages/V2/Programs/Define";
import Choosing from "./pages/V2/Programs/Choosing";
import T3mem from "./pages/V2/Programs/T3mem";
import Diff from "./pages/V2/Programs/Diff";
import Tameez from "./pages/V2/Programs/Tameez";
import SubProgramVerbs from "./pages/V2/OtherPrograms/Verbs/SubProgram";
import SubProgramVerbsDefine from "./pages/V2/OtherPrograms/Verbs/define";
import VerbsDefineShow from "./pages/V2/OtherPrograms/Verbs/define/defineShow";
import SubProgramVerbsChoose from "./pages/V2/OtherPrograms/Verbs/choose";
import VerbsChooseShow from "./pages/V2/OtherPrograms/Verbs/choose/chooseShow";

import VerbsT3mem from "./pages/V2/OtherPrograms/Verbs/t3mem";
import VerbsTameez from "./pages/V2/OtherPrograms/Verbs/Tameez";
import VerbsUse from "./pages/V2/OtherPrograms/Verbs/useVerbs";
import Jobs from "./pages/V2/OtherPrograms/Jobs";
import JobsSubProgram from "./pages/V2/OtherPrograms/Jobs/JobsProgram";
import Things from "./pages/V2/OtherPrograms/Things";
import Relations from "./pages/V2/OtherPrograms/Relations";
import SubProgramOpposite from "./pages/V2/OtherPrograms/Opposite/SubProgram";
import OppositeDefine from "./pages/V2/OtherPrograms/Opposite/define";
import OppositeDefineShow from "./pages/V2/OtherPrograms/Opposite/define/defineShow";
import OppositeChoose from "./pages/V2/OtherPrograms/Opposite/Choose";
import SubProgramAdverb from "./pages/V2/OtherPrograms/adverb/SubProgram";
import AdverbDefine from "./pages/V2/OtherPrograms/adverb/define";
import AdverbDefineShow from "./pages/V2/OtherPrograms/adverb/define/defineShow";
import AdverbChoose from "./pages/V2/OtherPrograms/adverb/Choose";
import SubProgramPrepos from "./pages/V2/OtherPrograms/prepos/SubProgram";
import PreposDefine from "./pages/V2/OtherPrograms/prepos/define";
import PreposDefineShow from "./pages/V2/OtherPrograms/prepos/define/defineShow";
import PreposChoose from "./pages/V2/OtherPrograms/prepos/Choose";
import PreposQuestions from "./pages/V2/OtherPrograms/prepos/questions";
import SubProgramTasnif from "./pages/V2/OtherPrograms/tasnif/SubProgram";
import TasnifDefine from "./pages/V2/OtherPrograms/tasnif/Define";
import TasnifDefineShow from "./pages/V2/OtherPrograms/tasnif/Define/DefineShow";
import TasnifChoose from "./pages/V2/OtherPrograms/tasnif/Choose";
import AdvChoose from "./pages/V2/OtherPrograms/tasnif/AdvChoose";
import SubProgramMufrad from "./pages/V2/OtherPrograms/mufrad/SubProgram";
import MufradDefine from "./pages/V2/OtherPrograms/mufrad/Define";
import MufradChoose from "./pages/V2/OtherPrograms/mufrad/Choose";
import SubPrograExpression from "./pages/V2/OtherPrograms/expression/SubProgram";
import ExpressionDefine from "./pages/V2/OtherPrograms/expression/Define";
import ExpressionChoose from "./pages/V2/OtherPrograms/expression/Choose";
import SubProgramNegation from "./pages/V2/OtherPrograms/negation/SubProgram";
import NegationChoose from "./pages/V2/OtherPrograms/negation/game";
import SubProgramConjunction from "./pages/V2/OtherPrograms/conjunction/SubProgram";
import ConjunctionGamePage from "./pages/V2/OtherPrograms/conjunction/game";
import WhatsWrongGame from "./pages/V2/OtherPrograms/whatsWrong";
import WhatsDiffGame from "./pages/V2/OtherPrograms/whatsDiff";
import SubProgramTafdil from "./pages/V2/OtherPrograms/tafdil/SubProgram";
import TafdilDefinePage from "./pages/V2/OtherPrograms/tafdil/define";
import TafdilChoosePage from "./pages/V2/OtherPrograms/tafdil/choose";
import WhatsBelong from "./pages/V2/OtherPrograms/whatsBelongsTo";
import PartOfAll from "./pages/V2/OtherPrograms/partofall";
import Visual from "./pages/V2/OtherPrograms/visual";
import SubProgramVoice from "./pages/V2/OtherPrograms/voice/SubProgram";
import VoiceDefineGame from "./pages/V2/OtherPrograms/voice/define";
import VoiceChooseGame from "./pages/V2/OtherPrograms/voice/choose";
import SubProgramZman from "./pages/V2/OtherPrograms/zman/SubProgram";
import SubProgramZamanDefineList from "./pages/V2/OtherPrograms/zman/define";
import ZamanDefineShow from "./pages/V2/OtherPrograms/zman/define/defineShow";
import SubProgramPronoun from "./pages/V2/OtherPrograms/pronoun/SubProgram";
import SubProgramPronounDefineList from "./pages/V2/OtherPrograms/pronoun/define";
import PronounDefineShow from "./pages/V2/OtherPrograms/pronoun/define/defineShow";
import PronounChooseGame from "./pages/V2/OtherPrograms/pronoun/choose";
import SubProgramStory from "./pages/V2/OtherPrograms/story/SubProgram";
import SubProgramStoryDefineList from "./pages/V2/OtherPrograms/story/define";
import StoryDefineShow from "./pages/V2/OtherPrograms/story/define/defineShow";
import StoryChooseGame from "./pages/V2/OtherPrograms/story/choose";
import SubProgramMuzakr from "./pages/V2/OtherPrograms/muzakr/SubProgram";
import SubProgramMuzakrDefineList from "./pages/V2/OtherPrograms/muzakr/define";
import MuzakrDefineGame from "./pages/V2/OtherPrograms/muzakr/define/defineShow";
import MuzakrChooseGame from "./pages/V2/OtherPrograms/muzakr/choose";
import SubProgramGomla from "./pages/V2/OtherPrograms/gomla/SubProgram";
import GomlaInPage from "./pages/V2/OtherPrograms/gomla/define";
import MaharatWZakara from "./pages/Programs/maharatWzakra";
import Tada3ySam3y from "./pages/V2/zakra/tada3ySam3y";
import AsalaaList from "./pages/V2/zakra/asala";
import AsalaaShow from "./pages/V2/zakra/asala/asalaShow";
import Estabal from "./pages/V2/zakra/estabal";
import Bsrya from "./pages/V2/zakra/bsrya";
import ClosingGame from "./pages/V2/zakra/closing";
import ZakraBasryaWsam3ya from "./pages/V2/zakra/zakeraBasryaWsm3ya";
import ZakraVisionGame from "./pages/V2/zakra/vision";
import MaharatWKraa from "./pages/Programs/maharatKraa";
import Tamyeez7roof from "./pages/V2/kraa/tamyeez7roof";
import ChooseDiffTamiez from "./pages/V2/kraa/chooseDiff";
import TamiezSoty7rof from "./pages/V2/kraa/tamiezSoty7rof";
import Exams from "./pages/Programs/exams";
import ExamsShow from "./pages/V2/Exams/ExamsShow";
import EndTest from "./pages/V2/Exams/EndTest";
import MyResults from "./pages/V2/Exams/MyResults";
import MyResultsShow from "./pages/V2/Exams/MyResultsShow";
import "./custom.css";
import TeacherDetails from "./pages/Teachers/TeacherDetails";
import TeachersPage from "./pages/Teachers";
// import Things from "./pages/V2/OtherPrograms/Things";

const App = () => {
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

	return (
		<>
		<ToastContainer />
			<Header />
			<div className="main__content">

				<Routes>
					<Route path="/login" element={<Login />} />
					<Route path="/signup" element={<Signup />} />
					<Route path="/" element={<Home />} />
					<Route path="/home" element={<Home />} />
					<Route path="/edit-profile" element={<EditProfilePage />} />
					<Route path="/programs" element={<TakativeProgramPage />} />

					<Route path="/programs/:id" element={<CategoryShow />} />
					<Route path="/teachers" element={<TeachersPage />} />
					<Route path="/teachers/:id" element={<TeacherDetails />} />
					<Route path="courses" element={<CoursesPage />} />
					<Route path="/tests" element={<TestsPage />} />
					{/* Categories collection */}
					<Route
						exact
						path="/programs/category/:program/:name/:id"
						element={<SubProgram />}
					/>

					<Route
						exact
						path="/programs/category/photo/:name/:id/:page"
						element={<Define />}
					/>
					<Route
						exact
						path="/programs/category/choose/:name/:id/:page"
						element={<Choosing />}
					/>
					<Route
						exact
						path="/programs/category/gallary/:name/:id/:page"
						element={<T3mem />}
					/>
					<Route
						exact
						path="/programs/category/diff/:name/:id/:page"
						element={<Diff />}
					/>
					<Route
						exact
						path="/programs/category/dist/:name/:id/:page"
						element={<Tameez />}
					/>
					{/* End categories verbs */}
					<Route exact path="/programs/verbs" element={<SubProgramVerbs />} />

					<Route
						exact
						path="/programs/verbs/define"
						element={<SubProgramVerbsDefine />}
					/>

					<Route
						exact
						path="/programs/verbs/define/:id/:subId"
						element={<VerbsDefineShow />}
					/>
					<Route
						exact
						path="/programs/verbs/choose"
						element={<SubProgramVerbsChoose />}
					/>
					<Route
						exact
						path="/programs/verbs/choose/:id/:subId"
						element={<VerbsChooseShow />}
					/>

					<Route
						exact
						path="/programs/verbs/t3mem/:page/:id"
						element={<VerbsT3mem />}
					/>
					<Route
						exact
						path="/programs/verbs/ttabk/:page/:id"
						element={<VerbsT3mem />}
					/>
					<Route
						exact
						path="/programs/verbs/tameez/:id"
						element={<VerbsTameez />}
					/>
					<Route
						exact
						path="/programs/verbs/verbuse/:id"
						element={<VerbsUse />}
					/>
					<Route exact path="/programs/jobs" element={<Jobs />} />
					<Route exact path="/programs/jobs/:id" element={<JobsSubProgram />} />
					<Route exact path="/programs/things" element={<Things />} />
					<Route exact path="/programs/relations" element={<Relations />} />
					<Route
						exact
						path="/programs/opposite"
						element={<SubProgramOpposite />}
					/>
					<Route
						exact
						path="/programs/opposite/define"
						element={<OppositeDefine />}
					/>
					<Route
						exact
						path="/programs/opposite/define/:id"
						element={<OppositeDefineShow />}
					/>
					<Route
						exact
						path="/programs/opposite/choose"
						element={<OppositeChoose />}
					/>
					<Route exact path="/programs/adverb" element={<SubProgramAdverb />} />
					<Route
						exact
						path="/programs/adverb/define"
						element={<AdverbDefine />}
					/>
					<Route
						exact
						path="/programs/adverb/define/:id"
						element={<AdverbDefineShow />}
					/>
					<Route
						exact
						path="/programs/adverb/choose"
						element={<AdverbChoose />}
					/>
					<Route exact path="/programs/prepos" element={<SubProgramPrepos />} />
					<Route
						exact
						path="/programs/prepos/define"
						element={<PreposDefine />}
					/>
					<Route
						exact
						path="/programs/prepos/define/:id"
						element={<PreposDefineShow />}
					/>
					<Route
						exact
						path="/programs/prepos/choose/:id"
						element={<PreposChoose />}
					/>
					<Route
						exact
						path="/programs/prepos/question/:id"
						element={<PreposQuestions />}
					/>

					<Route exact path="/programs/tasnif" element={<SubProgramTasnif />} />
					<Route
						exact
						path="/programs/tasnif/define"
						element={<TasnifDefine />}
					/>
					<Route
						exact
						path="/programs/tasnif/define/:id"
						element={<TasnifDefineShow />}
					/>
					<Route
						exact
						path="/programs/tasnif/choose/:id"
						element={<TasnifChoose />}
					/>
					<Route
						exact
						path="/programs/tasnif/advchoose/:id"
						element={<AdvChoose />}
					/>
					<Route
						exact
						path="/programs/mufrad/:page"
						element={<SubProgramMufrad />}
					/>
					<Route
						exact
						path="/programs/mufrad/:page/:subPage/define/:id"
						element={<MufradDefine />}
					/>
					<Route
						exact
						path="/programs/mufrad/:page/:subPage/choose/:id"
						element={<MufradChoose />}
					/>
					<Route
						exact
						path="/programs/expression"
						element={<SubPrograExpression />}
					/>
					<Route
						exact
						path="/programs/expression/define"
						element={<ExpressionDefine />}
					/>
					<Route
						exact
						path="/programs/expression/choose/:id"
						element={<ExpressionChoose />}
					/>
					<Route
						exact
						path="/programs/negation"
						element={<SubProgramNegation />}
					/>
					<Route
						exact
						path="/programs/negation/:page/:id"
						element={<NegationChoose />}
					/>
					<Route
						exact
						path="/programs/conjunction"
						element={<SubProgramConjunction />}
					/>
					<Route
						exact
						path="/programs/conjunction/:page/:id"
						element={<ConjunctionGamePage />}
					/>
					<Route exact path="/programs/tafdil" element={<SubProgramTafdil />} />
					<Route
						exact
						path="/programs/tafdil/define/:id"
						element={<TafdilDefinePage />}
					/>
					<Route
						exact
						path="/programs/tafdil/choose/:id"
						element={<TafdilChoosePage />}
					/>
					<Route
						exact
						path="/programs/whatsWrong/:id"
						element={<WhatsWrongGame />}
					/>
					<Route
						exact
						path="/programs/whatsDiffrent/:id"
						element={<WhatsDiffGame />}
					/>
					<Route
						exact
						path="/programs/whatsBelong/:id"
						element={<WhatsBelong />}
					/>
					<Route exact path="/programs/partOfAll/:id" element={<PartOfAll />} />
					<Route exact path="/programs/visual/:id" element={<Visual />} />

					<Route exact path="/programs/voice" element={<SubProgramVoice />} />
					<Route
						exact
						path="/programs/voice/define"
						element={<VoiceDefineGame />}
					/>
					<Route
						exact
						path="/programs/voice/choose/:id"
						element={<VoiceChooseGame />}
					/>

					<Route exact path="/programs/zaman" element={<SubProgramZman />} />
					<Route
						exact
						path="/programs/zaman/define"
						element={<SubProgramZamanDefineList />}
					/>
					<Route
						exact
						path="/programs/zaman/define/:id"
						element={<ZamanDefineShow />}
					/>
					<Route exact path="/programs/zaman/choose/:id" element={<Visual />} />

					<Route
						exact
						path="/programs/pronoun"
						element={<SubProgramPronoun />}
					/>
					<Route
						exact
						path="/programs/pronoun/define"
						element={<SubProgramPronounDefineList />}
					/>
					<Route
						exact
						path="/programs/pronoun/define/:id"
						element={<PronounDefineShow />}
					/>
					<Route
						exact
						path="/programs/pronoun/choose/:id"
						element={<PronounChooseGame />}
					/>

					<Route exact path="/programs/story" element={<SubProgramStory />} />
					<Route
						exact
						path="/programs/story/define"
						element={<SubProgramStoryDefineList />}
					/>
					<Route
						exact
						path="/programs/story/define/:id"
						element={<StoryDefineShow />}
					/>
					<Route
						exact
						path="/programs/story/name/:id"
						element={<StoryChooseGame />}
					/>

					<Route exact path="/programs/muzakr" element={<SubProgramMuzakr />} />
					<Route
						exact
						path="/programs/muzakr/define"
						element={<SubProgramMuzakrDefineList />}
					/>
					<Route
						exact
						path="/programs/muzakr/define/:id"
						element={<MuzakrDefineGame />}
					/>
					<Route
						exact
						path="/programs/muzakr/choose/:id"
						element={<MuzakrChooseGame />}
					/>

					<Route exact path="/programs/gomla" element={<SubProgramGomla />} />
					<Route
						exact
						path="/programs/gomla/:page/:id"
						element={<GomlaInPage />}
					/>

					{/* Maharat w zakra */}
					<Route path="/zakra" element={<MaharatWZakara />} />
					<Route path="/zakra/tada3ySam3y/:id" element={<Tada3ySam3y />} />
					<Route path="/zakra/asalaa" element={<AsalaaList />} />
					<Route path="/zakra/asalaa/:id/:subId" element={<AsalaaShow />} />
					<Route path="/zakra/estabal/:id" element={<Estabal />} />
					<Route path="/zakra/bsrya/:id" element={<Bsrya />} />
					<Route path="/zakra/closing/:id" element={<ClosingGame />} />
					<Route
						path="/zakra/bsryawsm3ya/:id"
						element={<ZakraBasryaWsam3ya />}
					/>
					<Route path="/zakra/vision/:id" element={<ZakraVisionGame />} />
					{/* Maharat W Kraa */}
					<Route path="/kraa" element={<MaharatWKraa />} />
					<Route path="/kraa/tamyeez/:page/:id" element={<Tamyeez7roof />} />
					<Route path="/kraa/tamiezdiff/:id" element={<ChooseDiffTamiez />} />
					<Route
						path="/kraa/tamyeezSoty/:page/:id"
						element={<TamiezSoty7rof />}
					/>

					{/* Exams */}
					<Route exact path="/exams" element={<Exams />} />
					<Route exact path="/exams/end" element={<EndTest />} />
					<Route exact path="/exams/results" element={<MyResults />} />
					<Route exact path="/exams/show/:id" element={<ExamsShow />} />
					<Route exact path="/exams/results/:id" element={<MyResultsShow />} />
				</Routes>
			</div>
			<Footer />
		</>
	);
};

export default App;
