import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Line } from "../../icons";
import Image from "../../components/images/Saly-12.png";
import Input from "../../components/Input";
import Card from "../../UI/Card";
import Tag from "../../components/images/image 11.png";
import { login, register } from "../../redux/slices/Auth/auth";
import { clearMessage } from "../../redux/slices/Auth/message";
import "./Auth.css";
import axios from "axios";
import EGYPT from "../../components/images/image 21.png";
import SKA from "../../components/images/image 20.png";
const Signup = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [signIn, setSignIn] = useState(false);

  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);
  const handleLogin = (e) => {
    // //console.log(data.email, data.password);
    // axios.post(
    //   "https://maharttafl.com/api/login",
    //   {
    //     email: data.email,
    //     password: data.password,
    //   },
    //   {
    //     headers: {
    //       Accept: "application/json",
    //     },
    //   }
    // );
    e.preventDefault();
    dispatch(login({ email: data.email, password: data.password }))
      .unwrap()
      .then((res) => {
        navigate("/");
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const handleRegister = (e) => {
    e.preventDefault();
    dispatch(
      register({
        name: data.name,
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation,
        phone: data.phone,
        locale: data.locale,
      })
    )
      .unwrap()
      .then((res) => {
        setSignIn(true);
        // navigate("/login");
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const resourses = [
    {
      type: "email",
      placeholder: "البريد الاليكتروني",
      id: "email",
    },
    {
      type: "password",
      placeholder: "كلمه السر",
      id: "password",
    },
  ];

  const resourses2 = [
    {
      type: "text",
      placeholder: "الاسم",
      id: "name",
    },
    {
      type: "number",
      placeholder: "رقم الموبايل",
      id: "phone",
    },
    {
      type: "email",
      placeholder: "البريد الاليكتروني",
      id: "email",
    },
    {
      type: "password",
      placeholder: "كلمه السر",
      id: "password",
    },
    {
      type: "password",
      placeholder: "تأكيد كلمة السر",
      id: "password_confirmation",
    },
  ];
  return (
    <Card radius="10px">
      <div className="login__popup">
        <div className="rigth">
          <h2 className="login__popup__title">
            <img src={Tag} alt="" className="tag" />
            {signIn ? "مرحبا بعودتك" : "مرحبا بك معنا"}
          </h2>
          <p className="login__popup__subtitle">
            {signIn
              ? "يرجي تسجيل الدخول للوصول لنظامك التعليمي"
              : "يرجي تسجيل بياناتك للانضمام الي النظام التعليمي"}
          </p>
          <form
            className="login__form"
            onSubmit={(e) => (signIn ? handleLogin(e) : handleRegister(e))}
          >
            {signIn &&
              resourses.map((input) => (
                <div className="login__form__inputs__container" key={input.id}>
                  <Input
                    key={input.id}
                    type={input.type}
                    placeholder={input.placeholder}
                    id={input.id}
                    data={data}
                    setData={setData}
                  />
                </div>
              ))}
            {!signIn &&
              resourses2.map((input) => (
                <div className="login__form__inputs__container" key={input.id}>
                  <Input
                    key={input.id}
                    type={input.type}
                    placeholder={input.placeholder}
                    id={input.id}
                    data={data}
                    setData={setData}
                  />
                </div>
              ))}
            {!signIn ? (
              <div className="booking__content">
                <div className="rigth__col">
                  <div className="box__title">اختار الدوله</div>
                  <div className="radio__inputs__container">
                    <div className="radio__country__box">
                      <input
                        type="radio"
                        name="choose__country"
                        id="egypt"
                        value="egypt"
                        onClick={(e) => {
                          setData({ ...data, locale: "EG" });
                        }}
                      />
                      <label htmlFor="egypt">
                        <img src={EGYPT} alt="" />
                      </label>
                    </div>
                    <div className="radio__country__box">
                      <input
                        type="radio"
                        name="choose__country"
                        id="KSA"
                        value="KSA"
                        onClick={(e) => {
                          setData({ ...data, locale: "SA" });
                        }}
                      />
                      <label htmlFor="KSA">
                        <img src={SKA} alt="" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="login__btns">
              <button type="submit" className="submit__form">
                {signIn ? "تسجيل الدخول" : "انشاء حساب"}
              </button>
              <button className="forget__password">نسيت كلمه المرور</button>
            </div>
            <button
              className="switch__signin__signup"
              onClick={() => setSignIn((prev) => !prev)}
            >
              <Line />
              {signIn ? "عضو جديد ؟" : "لديك حساب ؟"}
              <Line />
            </button>
          </form>
        </div>
        <div className="left">
          <div className="login__image">
            <img src={Image || ""} alt="" />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Signup;
