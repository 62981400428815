import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// import Card from "../../UI/Card";
import {
  Card,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  useMediaQuery,
} from "@mui/material";

import "./Define.css";


import tasnifServices from "../../../../../redux/services/categories/tasnif/programs";

const TasnifDefine = () => {

  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    tasnifServices.define('tasnifchoose').then((res) => {
      setCategory(res.data.opposites);
      // //console.log(res.data);
      setIsLoading(false);
    });
  }, [isLoading]);

  return (
    <section className="learning__program__secttion">
      <Card>
        <div className="learning__container">
          {category.map((t) => (
            <Link to={`/programs/tasnif/define/${t.id}`} state={t}>
              <div className={`leaning__box opened`} key={t.id}>
                <div className="learning__image">
                  <img
                    src={`https://maharttafl.com/images/${t.avatar}`}
                    alt=""
                  />
                </div>
                <p className="leaning__title">{t.name}</p>
              </div>
            </Link>
          ))}
        </div>
      </Card>
    </section>
  );
};

export default TasnifDefine;
