import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

import "./Choosing.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Grid } from "@mui/material";
import { Pie } from "react-chartjs-2";
import examsServices from "../../../../redux/services/exams/programs";

import CustomizedTables from "./Table";
const MyResultsShow = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const [isLoading, setIsLoading] = useState(true);

  const [examDetails, setExamDetails] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    examsServices.myResultShow("myexamresult", id).then((res) => {
      setExamDetails(res.data);
      //console.log(res.data);
      setIsLoading(false);
    });
  }, []);
  if (isLoading) return <></>;
  const data = {
    labels: ["Wrong", "Correct"],
    datasets: [
      {
        label: "# of Votes",
        data: [examDetails.wrongz, examDetails.rightz],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };
  return (
    <div className="games__page">
      <div>
        <Grid
          container
          className="games__section container"
          justifyContent="center"
          textAlign="center"
        >
          <Grid item xs={12}>
            <h1> النتيجة {examDetails.exams.id}</h1>
            <p>تاريخ الاختبار {examDetails.exams.created_at}</p>
          </Grid>
          <Grid item xs={12}>
            <CustomizedTables
              columns={["#", "question", "answer"]}
              rows={examDetails.questions}
            />
          </Grid>
          <Grid item xs={12}>
            <Pie
              data={data}
              width={50}
              height={50}
              // style={{ width: "100px !important", height: "100px !important" }}
              options={{ maintainAspectRatio: false }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default MyResultsShow;
