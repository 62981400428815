import React, { useState } from "react";
import Input from "../Input";
import { RiArrowDropDownLine } from "react-icons/ri";

import "./EditAccountForm.css";
import { useSelector } from "react-redux";

const EditAccountForm = () => {
  const [data, setData] = useState(useSelector((state) => state.auth.user));
  // const user = ;
  //console.log("user=>", data);
  const resourses = [
    {
      type: "text",
      placeholder: "الاسم",
      id: "name",
      // value: `${data.name}`,
    },
    {
      type: "email",
      placeholder: "البريد الاليكتروني",
      id: "email",
      // value: `${user.email}`,
    },
    {
      type: "number",
      placeholder: "رقم الموبايل",
      id: "phone",
      // value: `${user.phone}`,
    },
    {
      type: "date",
      placeholder: "تاريخ الميلاد",
      id: "user__date",
    },
  ];
  //console.log(resourses)

  return (
    <div className="edit__account__form">
      <p className="edit__account__title">بيانات الحساب</p>
      <p className="edit__account__subtitle">
        اضغط علي حقل الادخال لتعديل بيانات حسابك
      </p>
      <form
        className="edit__form__container"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="fomr__inputs">
          {resourses.map((input) => (

            <div className="input__controle" key={input.id}>
              <Input
                type={input.type}
                placeholder={input.placeholder}
                id={input.id}
                data={data}
                value="1"
                setData={setData}
              />
            </div>
          ))}
          <div className="select__box__controle">
            <select className="seclect__box">
              <option value="الجنس">الجنس</option>
              <option value="ذكر">ذكر</option>
              <option value="انثى">انثى</option>
            </select>
            <RiArrowDropDownLine className="drop_icon" />
          </div>
          <div className="select__box__controle">
            <select className="seclect__box">
              <option value="الجنس">الدوله</option>
              <option value="مصر">مصر</option>
              <option value="السعودية">السعودية</option>
            </select>
            <RiArrowDropDownLine className="drop_icon" />
          </div>
        </div>
        <button className="edit__account__form__submit" type="submit">
          تأكيد
        </button>
      </form>
    </div>
  );
};

export default EditAccountForm;
