import React, { useState, useEffect } from "react";
import Card from "../../../UI/Card";

import Input from "../../../components/Input";

import parse from "html-react-parser";
import { Box, Button, Rating, Typography } from "@mui/material";
import Visa from "../../../components/images/visa.png";
import Mastercard from "../../../components/images/mastercard.png";
import PayPal from "../../../components/images/paypal.png";
import PayMob from "../../../components/images/paymobLogo.png";
import { MuiPhone } from '../../../components/MuiPhone';
import axiosRequest from '../../../helpers/axiosRequest';
import "./SpeechExperts.css";
import LocalStorageService from "../../../helpers/LocalStorage";
import {toast} from 'react-toastify';
const paymentMethods = [
	{
		img: Visa,
		value: "visa",
	},
	{
		img: Mastercard,
		value: "mastercard",
	},
	{
		img: PayMob,
		value: "paymob",
	},
	{
		img: PayPal,
		value: "paypal",
	},
];

const SpeechExperts = (props) => {
	const [data, setData] = useState(props.expert);

	const [phone, setPhone] = useState()
	const [email, setEmail] = useState("")
	const [total,setTotal] = useState(0);
	const [enablePayment , setEnablePayment]= useState(true);

	const [sessions, setSessions] = useState(props.sessions);
	const [dataInput, setDataInput] = useState(null);

	const onChoosePayment = (method) => {
		// console.log(method)
		setDataInput(method)
	};
const ratingAction = (rate)=>{
axiosRequest.post('rate/teacher',{
	teacher:data.id,
	rate:rate
}).then(res=>{console.log(res)}).catch(err=>{console.log(err)})
}
	const onSubmitData = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const coursesNumber = formData.get("num__of__course");
		const countryChoosed = formData.get("choose__country");
		const phoneFormat = phone.split(' ');
		const phoneCode = phoneFormat[0];
		const phoneNumber = phoneFormat[1];
		const submitedData = {
			countryChoosed,
			coursesNumber,
			phoneCode,
			phoneNumber,
			email,
			method:dataInput
		};
		axiosRequest.post('payment',{
			phoneCode:phoneCode,
			phone:phoneNumber,
			email:email.email,
			paymentMethod:total===0 ? 'freeCase':dataInput,
			session:coursesNumber,
			type:'sessions',

		}).then(res=>{
			// console.log(res)
			if(res.data.error){
				//console.log(res.data.error)
				toast.info(res.data.error, {
					position: toast.POSITION.TOP_CENTER
				  });
			}else{
			if(!res.data.paymentUrl){
				toast.success("لقد تم حجز الجلسة بنجاح وسيتم التواصل معكم في اقرب وقت", {
					position: toast.POSITION.TOP_CENTER
				  });
				//console.log('call you soon !')
			}else{
			window.location.replace(res.data.paymentUrl);}}
		}).catch(err=>{
			toast.error("حدث خطأ برجاء المحاوله بعد قليل او ارسال المشكله في تواصل معنا اسفل الصفحه", {
				position: toast.POSITION.TOP_CENTER
			  });
		})
		console.log(submitedData);
	};
	useEffect(()=>{
		if(total <= 0 ){
			 setEnablePayment(false);
			setDataInput(null)
			}
		else {setEnablePayment(true);}
	},[total])
	return (
		<section className="speech__experts" style={{ marginTop: 55 }}>
			<Card radius="20px">
				<div className="speech__content">
					<div className="monitor__content">
						<div className="rigth__col">
							<div className="monitor__image" style={{border:0,
								backgroundColor:'transparent '}}>
								<img  src={`https://maharttafl.com/images/${data.avatar}`} alt="" />
							</div>
							<div className="monitor__name">{data.name} </div>
							<Typography variant="body1">اخصائيه</Typography>
							<Box
								display={"flex"}
								alignItems={"center"}
								justifyContent={"center"}
								gap={0.2}
							>
									<Rating
									readOnly = {LocalStorageService.getAccessToken() ? false : true}
							value={data.average_rating || 5}
							onChange={(e, value) => ratingAction(value)}
						/>
								{/* <Rating readOnly value={data.average_rating ?? 5} />({data.average_rating ?? 5}) */}
							</Box>
						</div>
						<div className="left__col">
							<ul className="list__details">
								<div className="left__col">{parse(data.ilo)}</div>
							</ul>
						</div>
					</div>
					<form onSubmit={onSubmitData}>
						<div className="booking__form">
							<h4 className="booking__title">احجز الان</h4>
							<div className="booking__content">
								<div className="center__col">
									<MuiPhone
										value={phone}
										onChange={(phone) => setPhone(phone)}
									/>
								</div>
								<div className="center__col">
									<div className="fomr__inputs">


										<Input
											type="text"
											placeholder="برجاء كتابة البريد الالكتروني"
											id="email"
											name="email"
											data={email}
											setData={setEmail}
											value=""
										/>


									</div>

								</div>
							</div>
							
							<div className="booking__content">
							<div className="center__col" style={{width:'100%'}}>
									<div className="box__title">اختر عدد الجلسات</div>
									<div className="radio__inputs__container">
										{sessions.map((s) => {
											return (<>{s.price > 0 ? (<div className="radio__input__num-of-course">
												<input
													type="radio"
													name="num__of__course"
													value={s.id}
													id={s.id}
													onChange={()=>{setTotal(s.price)}}
												/>
												<label htmlFor={s.id}>{s.totalSessions}</label>
												
											</div>) :
												(<div className="radio__input__num-of-course">
													<input
														type="radio"
														name="num__of__course"
														value={s.id}
														id={s.id}
														onChange={()=>{setTotal(s.price)}}
													/>
													
													<label htmlFor={s.id}>جلسة مجانية</label>
												</div>
												)} </>)

										})}
									</div>
								</div>
								</div>
							{enablePayment ? <div className="booking__content">
								{/* <div className="rigth__col">
									<div className="box__title">اختار الدوله</div>
									<div className="radio__inputs__container">
										<div className="radio__country__box">
											<input
												type="radio"
												name="choose__country"
												id="egypt"
												value="egypt"
											/>
											<label htmlFor="egypt">
												<img src={EGYPT} alt="" />
											</label>
										</div>
										<div className="radio__country__box">
											<input
												type="radio"
												name="choose__country"
												id="KSA"
												value="KSA"
											/>
											<label htmlFor="KSA">
												<img src={SKA} alt="" />
											</label>
										</div>
									</div>
								</div> */}

						
								{/* <div className="left__col">
									<div className="box__title">حدد معاد الجلسه</div>
									<Input
										type="date"
										placeholder="حدد التاريخ هنا"
										id="date"
										data={dataInput}
										setData={setDataInput}
									/>
								</div> */}
								<div className="payment-box center__col" style={{width:'100%'}}>
									<div className="box__title">حدد طريقة الدفع</div>
									<Box
										className="payment-wrap"
										display={"flex"}
										gap={2}
										alignItems={"center"}
										justifyContent={"space-between"}
										flexWrap="wrap"
									>
										{paymentMethods.map(({ img, value }) => (
											<button
												key={value}
												className={`paymentmethod ${dataInput === value ? "selected" : ""
													}`}
												onClick={() => onChoosePayment(value)}
											>
												<img src={img} />
											</button>
										))}
									</Box>
								</div>
							</div> : <></>}
						</div>
						<div className="speech__footer">
							<div className="total__price">
								<div className="total">الاجمالي</div>
								<div className="price">{total} $</div>
							</div>
							<Button type="submit" >تأكيد الحجز</Button>
						</div>
					</form>
				</div>
			</Card>
		</section>
	);
};

export default SpeechExperts;
