import React, { useState, useEffect, Fragment } from "react";
import { Link, useParams } from "react-router-dom";

// import Card from "../../UI/Card";
import { Card } from "@mui/material";

import "./verbsDefine.css";
import ProgramsSerivce from "../../../../../redux/services/categories/Programs/programs";
import VerbsService from "../../../../../redux/services/categories/verbs/programs";
import zamanServices from "../../../../../redux/services/categories/zaman/programs";
import pronounServices from "../../../../../redux/services/categories/pronoun/programs";
import storyServices from "../../../../../redux/services/categories/story/programs";

const SubProgramStoryDefineList = () => {
  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    storyServices.define("storydefine").then((res) => {
      setCategory(res.data.zamans);
      // //console.log(res.data);
    });
  }, []);

  // useEffect(() => {}, [isLoading]);
  if (!isLoading) return <></>;
  return (
    <section className="learning__program__secttion">
      <Card>
        <div className="learning__container">
          {category.map((t) => {
            
            return (
              <Fragment key={t.id}>
                <Link
                  className="program__details__btn"
                  to={`/programs/story/define/${t.id}`}
                >
                  {t.name}
                </Link>
              </Fragment>
            );
          })}
        </div>
      </Card>
    </section>
  );
};

export default SubProgramStoryDefineList;
