import React,{useEffect , useState} from "react";
import { TeacherReviewItem } from "./ReviewItem";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import axiosRequest from '../../helpers/axiosRequest'
// Import Swiper styles
import { Container } from "react-bootstrap";
import "swiper/css";
import "swiper/css/pagination";
import "./ReviewsList.css";
import { Box } from "@mui/material";
import Title from "../Title";
import { ArrowBack, Close } from "@mui/icons-material";
import {Button} from "@mui/material";
import { useNavigate } from "react-router-dom";
const reviews = [
	{
		id: 1,
		name: "عمرو محمد",
		review: "Excellent experience! Would recommend!",
		rating: 5,
		reviewCount: 42,
		country: "البحيره",
		avatarSrc:
			"https://st4.depositphotos.com/1001599/25057/v/450/depositphotos_250574580-stock-illustration-web-design-development-concept-vector.jpg", // Replace with actual image URL
	},
	{
		id: 2,
		name: "عمرو المسلماني",
		review: "Excellent experience! Would recommend!",
		rating: 5,
		reviewCount: 42,
		country: "مصر",
		avatarSrc:
			"https://st4.depositphotos.com/1001599/25057/v/450/depositphotos_250574580-stock-illustration-web-design-development-concept-vector.jpg", // Replace with actual image URL
	},
	{
		id: 3,
		name: "عمرو المسلماني",
		review: "Excellent experience! Would recommend!",
		rating: 5,
		reviewCount: 42,
		country: "مصر",
		avatarSrc:
			"https://st4.depositphotos.com/1001599/25057/v/450/depositphotos_250574580-stock-illustration-web-design-development-concept-vector.jpg", // Replace with actual image URL
	},
	{
		id: 4,
		name: "عمرو المسلماني",
		review: "Excellent experience! Would recommend!",
		rating: 5,
		reviewCount: 42,
		country: "مصر",
		avatarSrc:
			"https://st4.depositphotos.com/1001599/25057/v/450/depositphotos_250574580-stock-illustration-web-design-development-concept-vector.jpg", // Replace with actual image URL
	},
	{
		id: 5,
		name: "عمرو المسلماني",
		review: "Excellent experience! Would recommend!",
		rating: 5,
		reviewCount: 42,
		country: "مصر",
		avatarSrc:
			"https://st4.depositphotos.com/1001599/25057/v/450/depositphotos_250574580-stock-illustration-web-design-development-concept-vector.jpg", // Replace with actual image URL
	},
	{
		id: 6,
		name: "عمرو المسلماني",
		review: "Excellent experience! Would recommend!",
		rating: 5,
		reviewCount: 42,
		country: "مصر",
		avatarSrc:
			"https://st4.depositphotos.com/1001599/25057/v/450/depositphotos_250574580-stock-illustration-web-design-development-concept-vector.jpg", // Replace with actual image URL
	},
	// Add more review objects as needed
];

const TeacherReviewsList = () => {
	const navigate = useNavigate();
	const [review , setReview] = useState([
		{
			id: 1,
			name: "عمرو محمد",
			review: "Excellent experience! Would recommend!",
			rating: 5,
			reviewCount: 42,
			country: "البحيره",
			avatarSrc:
				"https://st4.depositphotos.com/1001599/25057/v/450/depositphotos_250574580-stock-illustration-web-design-development-concept-vector.jpg", // Replace with actual image URL
		},
		{
			id: 2,
			name: "عمرو المسلماني",
			review: "Excellent experience! Would recommend!",
			rating: 5,
			reviewCount: 42,
			country: "مصر",
			avatarSrc:
				"https://st4.depositphotos.com/1001599/25057/v/450/depositphotos_250574580-stock-illustration-web-design-development-concept-vector.jpg", // Replace with actual image URL
		},
		{
			id: 3,
			name: "عمرو المسلماني",
			review: "Excellent experience! Would recommend!",
			rating: 5,
			reviewCount: 42,
			country: "مصر",
			avatarSrc:
				"https://st4.depositphotos.com/1001599/25057/v/450/depositphotos_250574580-stock-illustration-web-design-development-concept-vector.jpg", // Replace with actual image URL
		},
		{
			id: 4,
			name: "عمرو المسلماني",
			review: "Excellent experience! Would recommend!",
			rating: 5,
			reviewCount: 42,
			country: "مصر",
			avatarSrc:
				"https://st4.depositphotos.com/1001599/25057/v/450/depositphotos_250574580-stock-illustration-web-design-development-concept-vector.jpg", // Replace with actual image URL
		},
		{
			id: 5,
			name: "عمرو المسلماني",
			review: "Excellent experience! Would recommend!",
			rating: 5,
			reviewCount: 42,
			country: "مصر",
			avatarSrc:
				"https://st4.depositphotos.com/1001599/25057/v/450/depositphotos_250574580-stock-illustration-web-design-development-concept-vector.jpg", // Replace with actual image URL
		},
		{
			id: 6,
			name: "عمرو المسلماني",
			review: "Excellent experience! Would recommend!",
			rating: 5,
			reviewCount: 42,
			country: "مصر",
			avatarSrc:
				"https://st4.depositphotos.com/1001599/25057/v/450/depositphotos_250574580-stock-illustration-web-design-development-concept-vector.jpg", // Replace with actual image URL
		},
	]);
	const onClick = () => navigate(`/teachers`);
	const [isLoading , setIsLoading] = useState(true);

	useEffect(() => {
	  axiosRequest.get('teachers').then(res=>{
		setReview(res.data.teachers.data);
// console.log(res)
	  }).catch(err=>{
		console.log(err)
	  })
	}, [isLoading])
	
	return (
		<>
		<Box pt={6}>
			<Title title="خبرائنا" align="center" />
			<Swiper
				className="teacher-reviews-swiper"
				dir="rtl"
				pagination={{ clickable: true }}
				modules={[Pagination]}
				slidesPerView="auto"
				spaceBetween={20}
			>
				{/* ADD6E5 */}
				{review.map((review) => (
					// <SplideSlide className="review-slide-item">
					<SwiperSlide key={review.id} className="review-slide-item">
						<TeacherReviewItem review={review} />
					</SwiperSlide>
					// </SplideSlide>
				))}
			</Swiper>
		</Box>
		<Box pt={6} style={{display:'flex',justifyContent:'center'}}>
		<Button
							className="start-link"
							style={{color:'white',background:'#ADD6E5',width:'50%'}}
							endIcon={<ArrowBack />}
							onClick={onClick}
						>
							المزيد من خبراء التخاطب
						</Button>
		</Box>
		</>
	);
};

export default TeacherReviewsList;
