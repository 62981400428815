import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import "./Box.css";
const Box = ({ text, onClick, disabled = false }) => {
  return (
    <Button
      className="gameButton"
      onClick={onClick}
      disabled={disabled}
      variant="contained"
      color="secondary"
    >
      {text}
    </Button>
  );
};

export default Box;
