import { Box, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import CommentItem from "./CommentItem";
import CommentForm from "./CommentForm";

const TeacherComments = ({teacherId,teacherComments}) => {
	
	const [comments, setComments] = useState(teacherComments);

	const addComment = (newComment) => {
		setComments([...comments, newComment]);
	};

	// useEffect(()=>{

	// },[isLoading])

	return (
		<Container>
			<Box my={1}>
				{comments.length <= 0 && (
					<Typography variant="h6">من فضلك قم بتقييم والتعليق</Typography>
				)}
				{comments.map((comment, index) => (
					<CommentItem key={index} comment={comment} />
				))}
			</Box>

			<CommentForm teacherId={teacherId} onAddComment={addComment} />
		</Container>
	);
};

export default TeacherComments;
