import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import "./tada3ySam3y.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Grid, Button } from "@mui/material";

import DefaultAudio from "../../../../helpers/defaultAudio";

import bsryaServices from "../../../../redux/services/zakra/bsrya/programs";

import Carousel from "../../../../components/Carousel";
import { Item } from "../../../../components/Carousel/styles";
import visionServices from "../../../../redux/services/zakra/vision/programs";

const ZakraVisionGame = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [start, setStart] = useState(true);
  const [mainImage, setMainImage] = useState(null);
  const [images, setImages] = useState([]);
  const [sound, setSound] = useState({
    choose: null,
    item: null,
    right: null,
    wrong: null,
  });
  useEffect(() => {
    visionServices.main("zakeravision", id ?? 6).then((res) => {
      //console.log(res.data);
      // setSound({
      //   choose: `https://maharttafl.com/sound/${res.data.sound.choose}`,
      //   item: `https://maharttafl.com/sound/${res.data.chooses.question}`,
      //   right: `https://maharttafl.com/sound/${res.data.sound.right}`,
      //   wrong: `https://maharttafl.com/sound/${res.data.sound.wrong}`,
      // });
      setMainImage(`https://maharttafl.com/images/${res.data.chooses.right}`);
      setImages(res.data.chooses.images);
      setIsLoading(false);
    });
  }, []);
  if (isLoading) return <></>;
  const playAudio = async (s) => {
    let audio = new Audio(s);
    await audio.play();
  };

  return (
    <div className="games__page">
      <div>
        <Grid
          spacing={4}
          container
          className="games__section container"
          justifyContent="center"
          textAlign="center"
        >
          {start ? (
            <Grid item xs={12}>
              <img
                src={mainImage}
                alt=""
                style={{
                  width: "150px",
                  height: "150px",
                  border: "1px solid #dedede",
                  borderRadius: "30%",
                }}
                onClick={() => {
                  setStart(false);
                }}
              />
            </Grid>
          ) : (
            <Carousel className="games__section container" keys={false}>
              {images.map((t, index) => {
                return (
                  <Item>
                    <img
                      src={`https://maharttafl.com/images/${t.image}`}
                      alt=""
                      style={{
                        width: "150px",
                        height: "150px",
                        border: "1px solid #dedede",
                        borderRadius: "30%",
                      }}
                      onClick={() => {
                        if (t.type === "wrong") {
                          DefaultAudio.wrong();
                          // setTimeout(() => {
                          //   playAudio(sound.wrong);
                          // }, 2500);
                        }
                        if (t.type === "right") {
                          DefaultAudio.clapp();
                          // setTimeout(() => {
                          //   playAudio(sound.right);
                          // }, 2500);
                          setStart(true);
                        }
                      }}
                    />
                  </Item>
                );
              })}
            </Carousel>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default ZakraVisionGame;
