import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


import { Grid, Typography } from "@mui/material";


import adverbServices from "../../../../../../redux/services/categories/adverb/programs";
const AdverbDefineShow = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id, subId } = useParams();
  const [images, setImages] = useState([]);
  const [oppositeName, setOppositeName] = useState("");
  const [selectedOpposite, setSelectedOpposite] = useState(null);
  useEffect(() => {
    adverbServices.defineShow("showadverbchoice", id).then((res) => {
      //console.log(res.data);
      setOppositeName(res.data.oppo.name);
      setImages(res.data.opposites);

      setIsLoading(false);
    });
  }, []);
  const playAudio = (a) => {
    let audio = new Audio(`https://maharttafl.com/sound/${a}`);
    audio.play();
  };
  if (isLoading) return <></>;
  return (
    <Grid
      container
      alignContent="center"
      justifyContent="center"
      textAlign="center"
      padding={4}
      marginTop={4}
      marginBottom={4}
      style={{ background: "white" }}
    >
      <Grid item xs={12}>
        <Typography variant="h4"> {oppositeName}</Typography>
      </Grid>
      {selectedOpposite ? (
        <Grid item xs={12}>
          <img
            src={`https://maharttafl.com/images/${selectedOpposite.avatar}`}
            alt=""
            style={{
              width: "150px",
              height: "150px",
              border: "1px solid #dedede",
              borderRadius: "30%",
            }}
            onClick={() => {
              playAudio(selectedOpposite.sound);
            }}
          />
        </Grid>
      ) : (
        <></>
      )}
      {images.map((e, index) => {
        return (
          <Grid item xs={6}>
            <img
              src={`https://maharttafl.com/images/${e.avatar}`}
              alt=""
              style={{
                width: "150px",
                height: "150px",
                border: "1px solid #dedede",
                borderRadius: "30%",
              }}
              onClick={() => {
                setSelectedOpposite(e);
                playAudio(e.sound);
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AdverbDefineShow;
