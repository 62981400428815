import React,{useState,useEffect} from "react";
import SingleProgram from "../SignleProgram";
import Title from "../Title";
import { Headphone, UsersIcon, Video } from "../../icons";
import axiosRequest from '../../helpers/axiosRequest';
import "./PricesSection.css";
import PriceItem from "./Price";
import { Grid } from "@mui/material";

const PricesSection = () => {
	const colors = ["#ADD6E5","AA4DB9","F05165"];
	const programes = [
		{
			id: 1,
			title: "البرنامج التخاطبي الناطق",
			subtitle: "لتعليم الطفل الكلام والنطق وتحسين اللغه.",
			icon: <Headphone />,
			iconColor: "#ADD6E5",
			packages: {
				title: "الشهر",
				values: [
					{
						plan: 1,
						egyPrice: 100,
						ksaPrice: 100,
					},
					{
						plan: 1,
						egyPrice: 100,
						ksaPrice: 100,
					},
					{
						plan: 1,
						egyPrice: 100,
						ksaPrice: 100,
					},
				],
			},
		},
		{
			id: 2,
			title: "جلسات اونلاين",
			subtitle: "لتحسين النطق والكلام عند الاطفال علي يد خبراء التخاطب ونطق..",
			icon: <UsersIcon />,
			iconColor: "#AA4DB9",
			packages: {
				title: "جلسات",
				values: [
					{
						plan: 1,
						egyPrice: 100,
						ksaPrice: 100,
					},
					{
						plan: 1,
						egyPrice: 100,
						ksaPrice: 100,
					},
				],
			},
		},
		{
			id: 3,
			title: "الكورسات",
			subtitle:
				"في التربيه الخاصه , التخاطب , النطق , التوحد ,و فرط الحركه وتشتت الانتباه..",
			icon: <Video />,
			iconColor: "#F05165",
			packages: {
				title: "الكورسات",
				values: [
					{
						plan: 1,
						egyPrice: 100,
						ksaPrice: 100,
					},
					{
						plan: 1,
						egyPrice: 100,
						ksaPrice: 100,
					},
				],
			},
		},
	];
	const [isLoading, setIsLoading] = useState(true);
	const [prices , setPrices] = useState([]);
	
	
	useEffect(() => {
		axiosRequest.get('sessions').then(res=>{
		  setPrices(res.data.sessions)
  //console.log(res)
		}).catch(err=>{
		  console.log(err)
		})
	  }, [isLoading])
	return (
		<section className="prices__section" id="prices">
			<div className="container">
				<Title title="الاسعار" align="center" />
				{/* <div className="prices__content"> */}
				<Grid container spacing={1.5}>
					{prices.map((price) => (
						<Grid item xs={12} sm={6} md={4}>
							<PriceItem
								key={price.id}
								title={price.name}
								subtitle={price.description}
								icon={price.totalSessions}
								iconColor={colors[Math.floor(Math.random() * colors.length)]}
								// packages={program.packages}
								id={price.id}
								isRights={true}
							/>
						</Grid>
					))}
				</Grid>
				{/* </div> */}
			</div>
		</section>
	);
};

// NOTE old code
/* 
  <SingleProgram
              key={program.id}
              title={program.title}
              subtitle={program.subtitle}
              icon={program.icon}
              type='price'
            />
*/

export default PricesSection;
