import React from "react";
import { Facebook, Instagram, YouTube } from "../../../icons";
import { SiWhatsapp } from "react-icons/si";
import "./HeaderTop.css";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
const HeaderTop = ({ isDiscount, isFooter }) => {
	const navigate = useNavigate();
	return (
		<div className={`${isFooter ? "footer-bar" : "header__top"}`}>
			<div className="rigth__nav">
				<Box display={"flex"} gap={0.5} alignItems={"center"}>
					<a
						href="https://www.instagram.com/maharttafl/"
						className="sociallink instagram"
						rel="noreferrer"
						target="_blank"
					>
						<Instagram className="header__icon" />
					</a>
					<a
						href="https://www.youtube.com/@user-di1xy5rm5s/"
						className="sociallink youtube"
						rel="noreferrer"
						target="_blank"
					>
						<YouTube className="header__icon" />
					</a>
					<a
						href="https://www.facebook.com/maharttafll"
						className="sociallink facebook"
						rel="noreferrer"
						target="_blank"
					>
						<Facebook className="header__icon" />
					</a>
				</Box>{" "}
				<p className="header__parag">تابعنا علي صفحات التواصل الاجتماعي</p>
			</div>
			{isDiscount && (
				<div className="center__nav">
					<button
					onClick={()=>{navigate('/teachers/3574')}}
					className="header__discount__btn">احجز جلستك المجانية</button>
					<p className="header__parag"></p>
				{/*	<p className="header__parag">
						نخبة من خبراء التخاطب 
			</p>*/}
				</div>
			)} 
			<div className="left__nav">
				<p className="header__parag">تواصل معنا</p>
				<a
					className="whatsapp_link"
					href="https://wa.me/966583617232"
					target="_blank"
				>
					<SiWhatsapp />
					<p className="header__number">+966583617232</p>
				</a>
			</div>
		</div>
	);
};

export default HeaderTop;
