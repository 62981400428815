import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import "./Define.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Grid } from "@mui/material";
import Carousel from "../../../../../components/Carousel";
import { Item } from "../../../../../components/Carousel/styles";
import expressionServices from "../../../../../redux/services/categories/expression/programs";
const ExpressionDefine = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { page, id } = useParams();
  const [magmoaa, setMagmoaa] = useState({});
  const [images, setImages] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sound, setSound] = useState("");
  useEffect(() => {
    expressionServices.define("expressiondefine").then((res) => {
      // setMagmoaa(res.data);
      // //console.log(res.data);
      // setSound(`https://maharttafl.com/sound/${res.data.content.sound}`);
      setImages(res.data.opposites);
      setIsLoading(false);
      // setImages([...images, res.data.content.photo.split(",")]);
    });
  }, [isLoading]);
  if (isLoading) return <></>;
  return (
    <div className="games__page">
      <section className="games__section container">
        <img
          src={`https://maharttafl.com/images/${images[currentSlide].avatar}`}
          alt=""
        />
      </section>
      <div className="container">
        <Carousel className="">
          {images.map((t, index) => {
            return (
              <Item>
                <img
                  src={`https://maharttafl.com/images/${t.avatar}`}
                  alt=""
                  style={{
                    width: "150px",
                    height: "150px",
                    border: "1px solid #dedede",
                    borderRadius: "30%",
                  }}
                  onClick={() => {
                    setCurrentSlide(index);
                    let audio = new Audio(
                      `https://maharttafl.com/sound/${t.sound}`
                    );
                    audio.play();
                  }}
                />
              </Item>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default ExpressionDefine;
