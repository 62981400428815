import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import categoryService from "../../../redux/services/categories/Category/categoryService";
// import Card from "../../UI/Card";
import {
  Card,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  useMediaQuery,
} from "@mui/material";

import "./maharatWzakra.css";

import ContactUs from "../../../components/ContactUs";
import HeroSection from "../../../components/HeroSection";

import OurProgrames from "../../../components/OurPrograms";
import Hero1 from "../../../components/images/hero1.png";
import Tag from "../../../components/images/image 11.png";
import Line from "../../../components/images/pngegg (1) 1.png";

const MaharatWKraa = () => {
  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    categoryService.category().then((res) => {
      setCategory(res.data.categories);
      // //console.log(res.data.categories);
      setIsLoading(false);
    });
  }, [isLoading]);

  const title = (
    <>
      علم{" "}
      <span>
        {" "}
        <img className="tag" src={Tag} alt="" /> طفلك{" "}
      </span>{" "}
      <br /> الكلام و النطق اونلاين
      <br /> مع{" "}
      <span>
        {" "}
        البرنامج التخاطبي{" "}
        <span>
          الناطق <img className="line" src={Line} alt="" />{" "}
        </span>{" "}
      </span>
    </>
  );
  const subtitle = (
    <>
      علي يد نخبه من<span> خبراء التخاطب </span> و النطق و تنميه <br />
      مهارات الاطفال
    </>
  );
  return (
    <div className="takative__page">
      <HeroSection
        title={title}
        subtitle={subtitle}
        image={Hero1}
        btnColor="#ADD6E5"
      />
      <div className="container">
        <OurProgrames />
      </div>
      <section className="learning__program__secttion">
        <Card>
          <div className="learning__container">
            <Link to={`/kraa/tamyeez/tamiezhorouf/1`}>
              <div className={`leaning__box opened`}>
                <div className="learning__image">
                  <img
                    src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                    alt=""
                  />
                </div>
                <p className="leaning__title">تمييز حروف</p>
              </div>
            </Link>
            <Link to={`/kraa/tamyeez/tamiezhoroufkelma/10`}>
              <div className={`leaning__box opened`}>
                <div className="learning__image">
                  <img
                    src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                    alt=""
                  />
                </div>
                <p className="leaning__title">تمييز حرف في كلمة</p>
              </div>
            </Link>
            <Link to={`/kraa/tamyeez/tamiezkelma/7`}>
              <div className={`leaning__box opened`}>
                <div className="learning__image">
                  <img
                    src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                    alt=""
                  />
                </div>
                <p className="leaning__title">تمييز كلمة</p>
              </div>
            </Link>
            <Link to={`/kraa/tamiezdiff/5`}>
              <div className={`leaning__box opened`}>
                <div className="learning__image">
                  <img
                    src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                    alt=""
                  />
                </div>
                <p className="leaning__title">اختيار المختلف</p>
              </div>
            </Link>
            <Link to={`/kraa/tamyeez/sourawekelma/2`}>
              <div className={`leaning__box opened`}>
                <div className="learning__image">
                  <img
                    src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                    alt=""
                  />
                </div>
                <p className="leaning__title">الصورة مع الكلمة</p>
              </div>
            </Link>
            <Link to={`/kraa/tamyeez/ekhtarkelma/5`}>
              <div className={`leaning__box opened`}>
                <div className="learning__image">
                  <img
                    src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                    alt=""
                  />
                </div>
                <p className="leaning__title">اختيار الكلمات</p>
              </div>
            </Link>
            <Link to={`/kraa/tamyeezSoty/tamiezhoroufsouty/4`}>
              <div className={`leaning__box opened`}>
                <div className="learning__image">
                  <img
                    src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                    alt=""
                  />
                </div>
                <p className="leaning__title">تمييز صوتي للحروف</p>
              </div>
            </Link>
            <Link to={`/kraa/tamyeezSoty/tamiezkelmasouty/4`}>
              <div className={`leaning__box opened`}>
                <div className="learning__image">
                  <img
                    src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                    alt=""
                  />
                </div>
                <p className="leaning__title">تمييز صوتي للكلمات</p>
              </div>
            </Link>
            <Link to={`/kraa/tamyeezSoty/tahagy/6`}>
              <div className={`leaning__box opened`}>
                <div className="learning__image">
                  <img
                    src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                    alt=""
                  />
                </div>
                <p className="leaning__title">تهجي</p>
              </div>
            </Link>
            =
          </div>
        </Card>
      </section>
      <ContactUs />
    </div>
  );
};

export default MaharatWKraa;
