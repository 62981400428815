import axiosRequest from "../../../../helpers/axiosRequest";
import env from "../../../../helpers/env";
const API_URL = env.baseURL;

const index = () => {
  return axiosRequest.get(API_URL + "teachers");
};

const TeachersService = {
  index,
};

export default TeachersService;
