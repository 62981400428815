import axios from "axios";
import env from "./env";
import LocalStorageService from "./LocalStorage";
const localStorageService = LocalStorageService.getService();
axios.defaults.baseURL = env.baseURL;

axios.interceptors.request.use(
  (config) => {
    const token = localStorageService.getAccessToken();
    config.headers["Accept"] = "application/json";
    config.headers["Accept-Language"] = "EG";
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      window.location.replace("/");
    }
    return Promise.reject(error);
  }
);

export default axios;
