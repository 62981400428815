import axiosRequest from "../../../../helpers/axiosRequest";
import env from "../../../../helpers/env";
const API_URL = env.baseURL;
const main = (url) => {
  return axiosRequest.get(API_URL + url);
};
const define = (url, id) => {
  return axiosRequest.get(API_URL + url );
};
const choose = (url, id) => {
  return axiosRequest.get(API_URL + url + "/" + id);
};

const tafdilServices = {
  main,
  define,
  choose,
};

export default tafdilServices;
