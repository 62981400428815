import React from "react";
import { Typography, CardContent, Rating, Box, Avatar } from "@mui/material";
import Card from "../../../../../UI/Card";
import "./CommentItem.css";
import { Timer } from "@mui/icons-material";

const CommentItem = ({ comment }) => {
	return (
		<Card className="comment-item">
			<CardContent>
				<Box display={"flex"} alignItems={"center"} gap={1} mb={1}>
					<Avatar src="/logo.png" />
					<div>
						<Typography variant="h6">{comment.name}</Typography>
						{/* <Box display={"flex"} alignItems={"center"} gap={0.2}>
							<Rating value={comment.rating} readOnly />
							<small>(4.5)</small>
						</Box> */}
					</div>
				</Box>
				<Typography noWrap={false}>{comment.comment}</Typography>
				<Box mt={2} display={"flex"} alignItems={"center"} gap={0.2}>
					<Timer className="clock" />
					<Typography variant="caption" className="date">
						{comment.created_at}
					</Typography>
				</Box>
			</CardContent>
		</Card>
	);
};

export default CommentItem;
