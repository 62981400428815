import React, { useState, useEffect } from "react";
import ContactUs from "../../components/ContactUs";
import HeroSection from "../../components/HeroSection";
import Image from "../../components/images/Rectangle 51.png";
import OurProgrames from "../../components/OurPrograms";
import SpeechExperts from "./SpeechExperts";
import Tag from "../../components/images/image 11.png";
import Line from "../../components/images/pngegg (1) 1.png";
import TeachersService from "../../redux/services/categories/Teachers/teachers";
import "./MeetingsPage.css";
import Title from "../../components/Title";
import ExpertCard from "./ExpertCard";
import { Grid } from "@mui/material";

const TEACHERS = [
	{
		id: "1t",
		name: "د. نورا محمد",
		ilo: "<ul><li>هذا النص غير صحيح فقط للتجربه</li></ul>",
		picture: "https://maharttafl.com/images/6448625da406d3.png",
		rating: 4.5,
		specialist: "تخاطب",
	},
	{
		id: "2t",
		name: "د. نورا محمد",
		ilo: "<ul><li>هذا النص غير صحيح فقط للتجربه</li></ul>",
		picture: "https://maharttafl.com/images/6448625da406d3.png",
		rating: 4.5,
		specialist: "تخاطب",
	},
	{
		id: "3t",
		name: "د. نورا محمد",
		ilo: "<ul><li>هذا النص غير صحيح فقط للتجربه</li></ul>",
		picture: "https://maharttafl.com/images/6448625da406d3.png",
		rating: 4.5,
		specialist: "تخاطب",
	},
	{
		id: "4t",
		name: "د. نورا محمد",
		ilo: "<ul><li>هذا النص غير صحيح فقط للتجربه</li></ul>",
		picture: "https://maharttafl.com/images/6448625da406d3.png",
		rating: 4.5,
		specialist: "تخاطب",
	},
	{
		id: "5t",
		name: "د. نورا محمد",
		ilo: "<ul><li>هذا النص غير صحيح فقط للتجربه</li></ul>",
		picture: "https://maharttafl.com/images/6448625da406d3.png",
		rating: 4.5,
		specialist: "تخاطب",
	},
	{
		id: "6t",
		name: "د. نورا محمد",
		ilo: "<ul><li>هذا النص غير صحيح فقط للتجربه</li></ul>",
		picture: "https://maharttafl.com/images/6448625da406d3.png",
		rating: 4.5,
		specialist: "تخاطب",
	},
	{
		id: "7t",
		name: "د. نورا محمد",
		ilo: "<ul><li>هذا النص غير صحيح فقط للتجربه</li></ul>",
		picture: "https://maharttafl.com/images/6448625da406d3.png",
		rating: 4.5,
		specialist: "تخاطب",
	},
];

const TeachersPage = () => {
	const [isLoading , setIsLoading] = useState(true);
	const [teachers, setTeachers] = useState(TEACHERS);
	useEffect(() => {
		window.scrollTo({ top: 0 });
		TeachersService.index().then((res) => {
			setTeachers(res.data.teachers.data);
		});
	}, [isLoading]);

	const title = (
		<>
			<span>
				<span>
					حسن <img className="tag" src={Tag} alt="" />
				</span>{" "}
				مهارات
			</span>{" "}
			النطق والكلام <br /> <span>لطفلك</span> عبر{" "}
			<span>
				الجلسات{" "}
				<span>
					الاونلاين
					<img src={Line} alt="" className="line" />
				</span>
			</span>
		</>
	);

	const subtitle = (
		<>
			علي يد نخبه من <span>خبراء التخاطب</span> و النطق و تنميه
			<br /> مهارات الاطفال
		</>
	);

	return (
		<>
			<HeroSection
				title={title}
				subtitle={subtitle}
				image={Image}
				btnColor="#AA4DB9"
			/>
			<OurProgrames />
			<div className="container">
				<Title
					lineWidth="175px"
					title="خبراء التخاطب"
					subtitle={
						<>
							احجز جلسات اونلاين مع خبراء التخاطب واللغه علي منصه{" "}
							<span>مهاره طفل</span>
						</>
					}
				/>
				<Grid container spacing={2}>
					{teachers.map((t) => {
						return (
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<ExpertCard key={t.id} expert={t} />
							</Grid>
						);
					})}
				</Grid>
			</div>
			<ContactUs />
		</>
	);
};

export default TeachersPage;
