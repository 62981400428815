import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import "./tada3ySam3y.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Grid, Button } from "@mui/material";

import tad3ySam3yServices from "../../../../redux/services/zakra/tada3ySam3y/programs";
import DefaultAudio from "../../../../helpers/defaultAudio";
const Tada3ySam3y = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id, page } = useParams();
  const [nextBack, setNextBack] = useState({
    next: null,
    back: null,
  });
  const [avatar, setAvatar] = useState("");

  const [sound, setSound] = useState({
    choose: null,
    item: null,
    right: null,
    wrong: null,
  });
  const [answer, setAnswer] = useState(null);
  const [mainImageHidden, setMainImageHidden] = useState(true);
  useEffect(() => {
    tad3ySam3yServices.main("tadaysamaay", id ?? 2).then((res) => {
      setSound({
        choose: `https://maharttafl.com/sound/${res.data.sound.choose}`,
        item: `https://maharttafl.com/sound/${res.data.chooses.question}`,
        right: `https://maharttafl.com/sound/${res.data.sound.right}`,
        wrong: `https://maharttafl.com/sound/${res.data.sound.wrong}`,
      });
      // setSound(`https://maharttafl.com/sound/${res.data.opposites.sound}`);
      // //console.log(res.data);
      // setImages({
      //   avatar1: res.data.opposites.avatar1,
      //   avatar2: res.data.opposites.avatar2,
      // });
      setAvatar(res.data.chooses.avatar);
      setAnswer(`https://maharttafl.com/sound/${res.data.chooses.answer}`);

      setNextBack({ ...nextBack, next: res.data.next });
      setNextBack({ ...nextBack, back: res.data.previous });
      setIsLoading(false);
      let audio = new Audio(res.data.chooses.question);
      audio.play();
    });
  }, []);
  const playAudio = async (s) => {
    // //console.log(s);
    let audio = new Audio(s);
    await audio.play();
  };

  if (isLoading) return <></>;

  return (
    <div className="games__page">
      <div>
        <Grid
          container
          className="games__section container"
          justifyContent="center"
          textAlign="center"
        >
          <Grid item xs={12}>
            <button
              style={{ width: "100%" }}
              onClick={() => {
                playAudio(sound.item);
              }}
            >
              Listen
            </button>
          </Grid>
          {mainImageHidden ? (
            <></>
          ) : (
            <Grid item xs={12}>
              <img
                src={`https://maharttafl.com/images/${avatar}`}
                alt=""
                style={{
                  width: "150px",
                  height: "150px",
                  border: "1px solid #dedede",
                  borderRadius: "30%",
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <button
              className="program__details__btn"
              onClick={() => {
                setMainImageHidden(false);
                playAudio(answer);
                setTimeout(() => {
                  // playAudio(sound.right);
                  DefaultAudio.clapp();
                }, 1300);
                setTimeout(() => {
                  playAudio(sound.right);
                }, 2700);
              }}
            >
              Answer
            </button>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Tada3ySam3y;
