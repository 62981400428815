import React, { useState, useEffect } from "react";

import "./Choosing.css";

import { Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const EndTest = (props) => {
  let details = null;
  const { state } = useLocation();

  return (
    <div className="games__page">
      <div>
        <Grid
          container
          className="games__section container"
          justifyContent="center"
          textAlign="center"
        >
          <Grid item xs={12}>
            <h1>شكراً علي اكمال الاختبار</h1>
          </Grid>
          {state ? (
            <Grid item xs={12}> 
              <p>عدد الاسئلة: {state.answerscount}</p>
              <p>الإجابات الصحيحة: {state.rightanswers}</p>
              <p>الإجابات الخاطئة: {state.wronganswers}</p>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default EndTest;
