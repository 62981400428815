import axiosRequest from "../../../helpers/axiosRequest";
import env from "../../../helpers/env";
const API_URL = env.baseURL;
const main = (page, url) => {
  return axiosRequest.get(API_URL + page);
};
const myResultShow = (page, url) => {
  return axiosRequest.get(API_URL + page + "/" + url);
};
const show = (page, url, params) => {
  if (params)
    return axiosRequest.get(API_URL + page + "/" + url + "?" + params);
  else return axiosRequest.get(API_URL + page + "/" + url);
};
const chooseAnswer = (page, url, data) => {
  return axiosRequest.post(API_URL + page + "/" + url, data);
};
const endTest = (page, data) => {
  return axiosRequest.get(API_URL + page + "?" + data);
};
const myResult = (page, url) => {
  return axiosRequest.get(API_URL + page);
};

const examsServices = {
  main,
  show,
  chooseAnswer,
  endTest,
  myResult,
  myResultShow
};

export default examsServices;
