import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import categoryService from "../../../redux/services/categories/Category/categoryService";
// import Card from "../../UI/Card";
import {
  Card,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  useMediaQuery,
} from "@mui/material";

import "./LearningProgram.css";
import SubProgramVerbs from "../../V2/OtherPrograms/Verbs/SubProgram";

const LearningProgram = () => {
  const [moreCategory, setMoreCategory] = useState([
    {
      id: 1020,
      name: " زمن الفعل",
      image: "images/5ea7f5ca8b61d_Mokhtar_ali.png",
      component: "",
    },
    {
      id: 1021,
      name: "الضمائر",
      image: "images/5ea7f5ca8b61d_Mokhtar_ali.png",
      component: "",
    },
    {
      id: 1022,
      name: "القصص",
      image: "images/5ea7f5ca8b61d_Mokhtar_ali.png",
      component: "",
    },
    {
      id: 1023,
      name: "المذكر والمؤنث",
      image: "images/5ea7f5ca8b61d_Mokhtar_ali.png",
      component: "",
    },
    {
      id: 1024,
      name: "الجمله",
      image: "images/5ea7f5ca8b61d_Mokhtar_ali.png",
      component: "",
    },
  ]);
  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    categoryService.category().then((res) => {
      setCategory(res.data.categories);
      // //console.log(res.data.categories);
      setIsLoading(false);
    });
  }, [isLoading]);

  return (
    <section className="learning__program__secttion">
      <Card>
        <div className="learning__container">
          {category.map((t) => (
            <Link to={`/programs/${t.id}`} state={t}>
              <div className={`leaning__box opened`} key={t.id}>
                <div className="learning__image">
                  <img
                    src={`https://maharttafl.com/images/${t.avatar}`}
                    alt=""
                  />
                </div>
                <p className="leaning__title">{t.name}</p>
              </div>
            </Link>
          ))}

          <Link to={`/programs/verbs`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">الافعال</p>
            </div>
          </Link>
          <Link to={`/programs/jobs`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">أماكن ومهن</p>
            </div>
          </Link>
          <Link to={`/programs/things`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">استخدام لاشياء</p>
            </div>
          </Link>
          <Link to={`/programs/relations`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">العلاقات</p>
            </div>
          </Link>
          <Link to={`/programs/opposite`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">المعكوسات</p>
            </div>
          </Link>
          <Link to={`/programs/adverb`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">ظرف المكان</p>
            </div>
          </Link>
          <Link to={`/programs/prepos`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">حروف الجر</p>
            </div>
          </Link>
          <Link to={`/programs/tasnif`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">التصنيف</p>
            </div>
          </Link>
          <Link to={`/programs/mufrad/muthana`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">مفرد ومثني</p>
            </div>
          </Link>
          <Link to={`/programs/mufrad/mufrad`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">مفرد وجمع</p>
            </div>
          </Link>
          <Link to={`/programs/expression`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">التعبيرات</p>
            </div>
          </Link>
          <Link to={`/programs/negation`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">النفي</p>
            </div>
          </Link>
          <Link to={`/programs/conjunction`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">حروف العطف</p>
            </div>
          </Link>
          <Link to={`/programs/whatsWrong/3`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">ما الخطأ</p>
            </div>
          </Link>
          <Link to={`/programs/whatsDiffrent/4`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">ما الاختلاف</p>
            </div>
          </Link>
          <Link to={`/programs/tafdil`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">التفضيل</p>
            </div>
          </Link>
          <Link to={`/programs/whatsBelong/3`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">من لا ينتمي</p>
            </div>
          </Link>
          <Link to={`/programs/partOfAll/6`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">الجزء من الكل</p>
            </div>
          </Link>
          <Link to={`/programs/visual/first`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">التمييز البصري</p>
            </div>
          </Link>
          <Link to={`/programs/voice`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">اصوات البيئة</p>
            </div>
          </Link>
          <Link to={`/programs/zaman`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">زمن الفعل</p>
            </div>
          </Link>
          <Link to={`/programs/pronoun`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">الضمائر</p>
            </div>
          </Link>
          <Link to={`/programs/story`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">القصص</p>
            </div>
          </Link>
          <Link to={`/programs/muzakr`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">المذكر والؤنث</p>
            </div>
          </Link>
          <Link to={`/programs/gomla`}>
            <div className={`leaning__box opened`}>
              <div className="learning__image">
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <p className="leaning__title">الجملة</p>
            </div>
          </Link>
        </div>
      </Card>
    </section>
  );
};

export default LearningProgram;
