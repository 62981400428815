import React, { useState, useEffect, Fragment } from "react";
import { Link, useParams } from "react-router-dom";

import {
  Grid,
  Card,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  useMediaQuery,
  Button,
} from "@mui/material";


import tasnifServices from "../../../../../../redux/services/categories/tasnif/programs";

const TasnifDefineShow = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [allPlaces, setAllPlaces] = useState([]);
  const [showPlaces, setShowPlaces] = useState([]);
  const [placeIndex, setPlaceIndex] = useState(0);
  useEffect(() => {
    tasnifServices.defineShow("tasnifcat", id).then((res) => {
      setAllPlaces(res.data.place);
      setShowPlaces([...showPlaces, res.data.place[0]]);
      // //console.log(res.data)
      setIsLoading(false);
      playAudio(res.data.place[0].sound);
    });
  }, []);

  const next = () => {
    if (allPlaces.length > placeIndex + 1) {
      setPlaceIndex(placeIndex + 1);
      setShowPlaces([...showPlaces, allPlaces[placeIndex + 1]]);
      playAudio(allPlaces[placeIndex + 1].sound);
    } else {
      setShowPlaces([allPlaces[0]]);
      setPlaceIndex(0);
      playAudio(allPlaces[0].sound);
    }
  };
  const playAudio = (audioP) => {
    let audio = new Audio(`https://maharttafl.com/sound/${audioP}`);
    audio.play();
  };
  return (
    <Grid
      container
      textAlign="center"
      justifyContent="center"
      padding={3}
      borderRadius={25}
      style={{ backgroundColor: "white" }}
    >
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            next();
          }}
        >
          {allPlaces.length === placeIndex + 1 ? "RESET" : "NEXT"}
        </Button>
      </Grid>
      {showPlaces
        .slice(0)
        .reverse()
        .map((e, index) => {
          return (
            <Grid item xs={4} key={index}>
              <img
                src={`https://maharttafl.com/images/${e.avatar}`}
                style={{ width: "100%", height: "100%" }}
                alt=""
              />
            </Grid>
          );
        })}
      <Grid item xs={3}></Grid>
    </Grid>
  );
};

export default TasnifDefineShow;
