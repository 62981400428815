import React, { useState,useEffect } from "react";
import Card from "../../../../../UI/Card";
import {
	Typography,
	TextField,
	Button,
	Box,
	Rating,
	Grid,
} from "@mui/material";
import axiosRequest from '../../../../../helpers/axiosRequest'
import "./CommentForm.css";
import Input from "../../../../../components/Input";

const resources = [
	{
		type: "text",
		placeholder: "الاسم",
		id: "user__name",
	},
	{
		type: "email",
		placeholder: "البريد الاليكتروني",
		id: "user__email",
	},
];

const CommentForm = ({ onAddComment,teacherId }) => {
	const [data, setData] = useState({});

	const handleAddComment = () => {
		const newComment = {
			name: data["user__name"],
			email: data["user__email"],
			// rating: data.rating,
			comment: data.comment,
			created_at: new Date().toLocaleDateString(),
		};
		axiosRequest.post(`comment/teacher/${teacherId}`,newComment).then(res=>{console.log(res)}).catch(err=>{console.log(err)})
		onAddComment(newComment);

		setData({});
	};

	return (
		<Card>
			<Box p={2} className="addcomment-form">
				<Typography variant="h6" className="title">
					اضافة تعليق
				</Typography>
				<form onSubmit={(e) => e.preventDefault()} className="contact__form">
					<div className="form__contact__content">
						<div className="contact__inputs">
							{resources.map((input) => (
								<div className="contact__input" key={input.id}>
									<Input
										key={input.id}
										type={input.type}
										placeholder={input.placeholder}
										id={input.id}
										data={data}
										setData={setData}
									/>
								</div>
							))}
						</div>
						<div className="contact__textarea">
							<textarea
								placeholder="اكتب تعليقك هنا "
								onChange={(e) => setData({ ...data, comment: e.target.value })}
								value={data.comment || ""}
							/>
						</div>
					</div>
					{/* <Box display={"flex"} alignItems={"center"} gap={0.2} mt={2}>
						<label>التقييم:</label>
						<Rating
							value={data.rating || 0}
							onChange={(e, value) => setData({ ...data, rating: value })}
						/>
					</Box> */}
					<button
						onClick={handleAddComment}
						type="submit"
						className="contact__form__submit"
					>
						اضافة تعليق
					</button>
				</form>
			</Box>
		</Card>
	);
};

export default CommentForm;
