import axiosRequest from "../../../helpers/axiosRequest";
import env from "../../../helpers/env";
const API_URL = env.baseURL;
const main = (page, url) => {
  return axiosRequest.get(API_URL + page + "/" + url);
};

const kraaServices = {
  main,
};

export default kraaServices;
