import axiosRequest from "../../../../helpers/axiosRequest";
import env from "../../../../helpers/env";
const API_URL = env.baseURL;
const main = (url) => {
  return axiosRequest.get(API_URL + url);
};
const game = (url, id) => {
  return axiosRequest.get(API_URL + url + "/" + id);
};


const conjunctionServices = {
  main,
  game,
  
};

export default conjunctionServices;
