import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import "./zamanChoose.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import Carousel from "../../../../../components/Carousel";
import { Item } from "../../../../../components/Carousel/styles";
import { Grid } from "@mui/material";
import pronounServices from "../../../../../redux/services/categories/pronoun/programs";
import storyServices from "../../../../../redux/services/categories/story/programs";
const StoryChooseGame = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id, subId } = useParams();
  const [nextBack, setNextBack] = useState({
    next: null,
    back: null,
  });
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState("");
  const [sound, setSound] = useState({
    choose: null,
    right: null,
    wrong: null,
  });
  useEffect(() => {
    storyServices.choose("storychoose", id).then((res) => {
      // setSound({
      //   choose: `https://maharttafl.com/sound/${res.data.sound.choose}`,
      //   item: `https://maharttafl.com/sound/${res.data.chooses.sound}`,
      //   right: `https://maharttafl.com/sound/${res.data.sound.right}`,
      //   wrong: `https://maharttafl.com/sound/${res.data.sound.wrong}`,
      // });
      // //console.log(res.data);
      setVideo(res.data.chooses.video);
      // setImages([
      //   {
      //     image: res.data.chooses.right,
      //     type: "right",
      //   },
      //   {
      //     image: res.data.chooses.wrong1,
      //     type: "wrong",
      //   },
      //   { image: res.data.chooses.wrong2, type: "wrong" },
      // ]);
      setNextBack({ ...nextBack, next: res.data.next });
      setNextBack({ ...nextBack, back: res.data.previous });
      setIsLoading(false);
    });
  }, []);
  const chooseAudio = async () => {
    let audio = new Audio(sound.choose);
    await audio.play();
  };
  const chooseAudioItem = async () => {
    let audio2 = new Audio(sound.item);
    audio2.play();
  };
  const clapp = () => {
    let audio = new Audio(`https://maharttafl.com/sound/clapp.mp3`);
    audio.play();
  };
  if (isLoading) return <></>;

  return (
    <div className="games__page">
      <div>
        <Grid container justifyContent="center" textAlign="center">
          <Grid item xs={12} style={{ backgroundColor: "white" }}>
            <video
              autoPlay={false}
              controls
              playing={false}
              preload="auto"
              style={{ maxWidth: "50%", height: "auto" }}
              src={`https://maharttafl.com/images/${video}`}
            ></video>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default StoryChooseGame;
