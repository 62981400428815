import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

import "./Choosing.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Grid } from "@mui/material";

import examsServices from "../../../../redux/services/exams/programs";
import CustomizedTables from "./Table";
const MyResults = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState([]);
  useEffect(() => {
    examsServices.myResult("myresult").then((res) => {
      setIsLoading(false);
      setResults(res.data.exams);
      // //console.log(res.data.exams);
    });
  }, []);
  if (isLoading) return <></>;

  return (
    <div className="games__page">
      <div>
        <Grid
          container
          className="games__section container"
          justifyContent="center"
          textAlign="center"
        >
          <CustomizedTables
            columns={["#", "result", "created", "show"]}
            rows={results}
          />
        </Grid>
      </div>
    </div>
  );
};

export default MyResults;
