import React, { useState, useEffect } from "react";

import "./SubProgramVerbs.css";
import Card from "../../../../../UI/Card";

import { Link, useLocation } from "react-router-dom";
import VerbsService from "../../../../../redux/services/categories/verbs/programs";
const SubProgramVerbs = (props) => {
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [category, setCategory] = useState({
    avatar: "",
    id: 1000,
    name: "الأفعال",
  });
  const [programsId, setProgramsId] = useState({
    diffs: null,
    same: null,
    useee: null,
    choosediffs: null,
  });
  useEffect(() => {
    VerbsService.main("verbs").then((res) => {
      setProgramsId(res.data);
      setIsLoading(false);
    });
    // //console.log(programsId);
  }, [isLoading]);
  if (isLoading) return <></>;

  return (
    <section className="prices__section">
      <div className="container" style={{ marginTop: 10 }}>
        <div className="prices__content">
          <Card radius="20px">
            <div className="single__program__box">
              <div className="program__icon">
                {/* <Headphone /> */}
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <div className="programe__title">{category.name}</div>

              {/* /programs/category/define/:name/:id */}
              <Link
                className="program__details__btn"
                to={`/programs/verbs/define`}
                state={category}
              >
                التعريف
              </Link>
              <Link
                className="program__details__btn"
                to={`/programs/verbs/choose`}
                state={category}
              >
                الاختيار
              </Link>
              <Link
                className="program__details__btn"
                to={`/programs/verbs/t3mem/verbdiffs/${programsId.diffs}`}
                state={category}
              >
                تعميم
              </Link>
              <Link
                className="program__details__btn"
                to={`/programs/verbs/ttabk/verbdiff/${programsId.choosediffs}`}
                state={category}
              >
                تطابق
              </Link>

              <Link
                className="program__details__btn"
                to={`/programs/verbs/tameez/${programsId.same}`}
                state={category}
              >
                التمييز العام
              </Link>

              <Link
                className="program__details__btn"
                to={`/programs/verbs/verbuse/${programsId.useee}`}
                state={category}
              >
                استخدام الأفعال
              </Link>
              {/* 
              <Link className="program__details__btn">تعميم</Link>
              <Link className="program__details__btn">تطابق</Link>
              {category.page ? (
                <Link className="program__details__btn">التمييز العام</Link>
              ) : (
                <></>
              )} */}
            </div>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default SubProgramVerbs;
