import axiosRequest from "../../../../helpers/axiosRequest";
import env from "../../../../helpers/env";
const API_URL = env.baseURL;
const main = (page, url) => {
  return axiosRequest.get(API_URL + page);
};
const define = (page, url) => {
  return axiosRequest.get(API_URL + page);
};
const defineShow = (page, url) => {
  return axiosRequest.get(API_URL + page + "/" + url);
};
const choose = (page, url) => {
  return axiosRequest.get(API_URL + page + "/" + url);
};

const pronounServices = {
  main,
  define,
  defineShow,
  choose,
};

export default pronounServices;
