import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProgramsSerivce from "../../../../redux/services/categories/Programs/programs";
import "./Relations.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import Carousel from "../../../../components/Carousel";
import { Item } from "../../../../components/Carousel/styles";
import RelationsServices from "../../../../redux/services/categories/relations/programs";
const Relations = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [nextBack, setNextBack] = useState({
    next: null,
    back: null,
  });
  const [images, setImages] = useState([]);
  const [masterImage, setMasterImage] = useState(null);
  const [mainText, setMainText] = useState("اختر الصورة المناسبة");

  useEffect(() => {
    RelationsServices.program("relation").then((res) => {
      setMainText(res.data.things.question);
      // //console.log(res.data);
      setImages(res.data.things.images);
      setMasterImage(res.data.things.right1);
      setNextBack({ ...nextBack, next: res.data.next });
      setNextBack({ ...nextBack, back: res.data.previous });
      setIsLoading(false);
    });
  }, []);

  const wrong = () => {
    let audio = new Audio(`https://maharttafl.com/sound/horn.mp3`);
    audio.play();
  };
  const clapp = () => {
    let audio = new Audio(`https://maharttafl.com/sound/clapp.mp3`);
    audio.play();
  };
  if (isLoading) return <></>;

  return (
    <div className="games__page">

      <section className="games__section container">
        <img src={`https://maharttafl.com/images/${masterImage}`} alt="" />
      </section>
      <div>
        <Carousel className="games__section container" keys={false}>
          {images.map((t, index) => {
            return (
              <Item>
                <img
                  src={`https://maharttafl.com/images/${t.image}`}
                  alt=""
                  style={{
                    width: "150px",
                    height: "150px",
                    border: "1px solid #dedede",
                    borderRadius: "30%",
                  }}
                  onClick={() => {
                    if (t.type === "wrong") {
                      wrong();
                    }
                    if (t.type === "right") {
                      clapp();
                    }
                  }}
                />
              </Item>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Relations;
