import axiosRequest from "../../../../helpers/axiosRequest";
import env from "../../../../helpers/env";
const API_URL = env.baseURL;
const main = (page, url) => {
  return axiosRequest.get(API_URL + page);
};
const show = (page, id, subId) => {
  return axiosRequest.get(API_URL + page + "/" + id + "/" + subId);
};

const asalaaServices = {
  main,
  show,
};

export default asalaaServices;
