import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import "./Choose.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Grid } from "@mui/material";
import tafdilServices from "../../../../../redux/services/categories/tafdil/programs";
import gomlaServices from "../../../../../redux/services/categories/gomla/programs";
const GomlaInPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id, page } = useParams();
  const [nextBack, setNextBack] = useState({
    next: null,
    back: null,
  });
  const [avatar, setAvatar] = useState("");

  const [sound, setSound] = useState(null);
  useEffect(() => {
    gomlaServices.game(page, id).then((res) => {
      // setSound({
      //   choose: `https://maharttafl.com/sound/${res.data.sound.choose}`,
      //   item: `https://maharttafl.com/sound/${res.data.opposites.sound}`,
      //   right: `https://maharttafl.com/sound/${res.data.sound.right}`,
      //   wrong: `https://maharttafl.com/sound/${res.data.sound.wrong}`,
      // });
      // setSound(`https://maharttafl.com/sound/${res.data.opposites.sound}`);
      //console.log(res.data);
      // setImages({
      //   avatar1: res.data.opposites.avatar1,
      //   avatar2: res.data.opposites.avatar2,
      // });
      setAvatar(res.data.chooses.avatar);
      setSound(res.data.chooses.sound);
      setNextBack({ ...nextBack, next: res.data.next });
      setNextBack({ ...nextBack, back: res.data.previous });
      setIsLoading(false);
      let audio = new Audio(res.data.chooses.sound);
      audio.play();
    });
  }, []);
  const playAudio = async () => {
    let audio = new Audio(sound);
    await audio.play();
  };

  if (isLoading) return <></>;

  return (
    <div className="games__page">
      <div>
        <Grid
          container
          className="games__section container"
          justifyContent="center"
          textAlign="center"
        >
          <Grid item xs={12}>
            <button
              style={{ width: "100%" }}
              onClick={() => {
                playAudio();
              }}
            >
              Listen
            </button>
          </Grid>
          <Grid item xs={12}>
            <img
              src={`https://maharttafl.com/images/${avatar}`}
              alt=""
              style={{
                width: "150px",
                height: "150px",
                border: "1px solid #dedede",
                borderRadius: "30%",
              }}
            />
          </Grid>

          <Grid item xs={12}></Grid>
        </Grid>
      </div>
    </div>
  );
};

export default GomlaInPage;
