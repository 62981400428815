import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import VerbsService from "../../../../../../redux/services/categories/verbs/programs";
import { Grid } from "@mui/material";
import { isRejected } from "@reduxjs/toolkit";
const VerbsDefineShow = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id, subId } = useParams();
  const [videos, setVideos] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  useEffect(() => {
    VerbsService.defineShow("verbsdefine", id, subId).then((res) => {
      // setMagmoaa(res.data);
      //console.log(res.data);
      setVideos([
        res.data.define.video1,
        res.data.define.video2,
        res.data.define.video3,
      ]);
      // setSound(`https://maharttafl.com/sound/${res.data.content.sound}`);
      // setImages(res.data.content.photo.split(","));
      setIsLoading(false);
      // setImages([...images, res.data.content.photo.split(",")]);
    });
  }, [isLoading]);
  const handleNext = () => {
    if (currentVideoIndex === videos.length - 1) setCurrentVideoIndex(0);
    else setCurrentVideoIndex(currentVideoIndex + 1);
  };
  const handleBack = () => {
    if (currentVideoIndex === 0) setCurrentVideoIndex(0);
    else setCurrentVideoIndex(currentVideoIndex - 1);
  };
  if (isLoading) return <></>;
  return (
    <Grid
      container
      alignContent="center"
      justifyContent="center"
      textAlign="center"
    >
      <Grid item xs={6}>
        <video
          autoPlay={true}
          controls
          playing={true}
          preload="auto"
          style={{ maxWidth: "100%", height: "auto" }}
          src={`https://maharttafl.com/images/${videos[currentVideoIndex]}`}
        ></video>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={6}>
        <button className="program__details__btn" onClick={handleBack}>
          {" "}
          Back
        </button>
      </Grid>
      <Grid item xs={6}>
        <button className="program__details__btn" onClick={handleNext}>
          {" "}
          Next
        </button>
      </Grid>
    </Grid>
  );
};

export default VerbsDefineShow;
