import React, { useState, useEffect } from "react";

import "./SubProgramAdverb.css";
import Card from "../../../../../UI/Card";

import { Link, useLocation } from "react-router-dom";
import VerbsService from "../../../../../redux/services/categories/verbs/programs";
import OppositeServices from "../../../../../redux/services/categories/opposite/programs";
import preposServices from "../../../../../redux/services/categories/prepos/programs";
const SubProgramPrepos = (props) => {
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [category, setCategory] = useState({
    avatar: "",
    id: 1000,
    name: "المعكوسات",
  });
  const [programsId, setProgramsId] = useState({
    choose: null,
    test: null,
  });
  useEffect(() => {
    preposServices.main("prepos").then((res) => {
      setProgramsId(res.data);
      setIsLoading(false);
      // //console.log(res.data);
    });
    // //console.log(programsId);
  }, [isLoading]);
  if (isLoading) return <></>;

  return (
    <section className="prices__section">
      <div className="container" style={{ marginTop: 10 }}>
        <div className="prices__content">
          <Card radius="20px">
            <div className="single__program__box">
              <div className="program__icon">
                {/* <Headphone /> */}
                <img
                  src={`https://maharttafl.com/images/5ea7f5ca8b61d_Mokhtar_ali.png`}
                  alt=""
                />
              </div>
              <div className="programe__title">{category.name}</div>

              <Link
                className="program__details__btn"
                to={`/programs/prepos/define`}
                state={category}
              >
                التعريف
              </Link>
              <Link
                className="program__details__btn"
                to={`/programs/prepos/choose/${programsId.choose}`}
                state={category}
              >
                الاختيار
              </Link>
              <Link
                className="program__details__btn"
                to={`/programs/prepos/question/${programsId.test}`}
                state={category}
              >
                الاسئله
              </Link>
            </div>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default SubProgramPrepos;
