import axiosRequest from "../../../../helpers/axiosRequest";
import env from "../../../../helpers/env";
const API_URL = env.baseURL;

const register = (
  name,
  email,
  password,
  password_confirmation,
  phone,
  locale
) => {
  return axiosRequest.post(API_URL + "register", {
    name,
    email,
    phone,
    password,
    password_confirmation,
    locale,
  });
};

const login = (email, password) => {
  // //console.log(email, password, "authService");
  return axiosRequest
    .post(API_URL + "login", {
      email,
      password,
    })
    .then((response) => {
      let str = response.data.access_token.replace(/^"(.*)"$/, "$1");

      // //console.log(str, "response>>");
      if (response.data.access_token) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("token", response.data.access_token);
      }

      return response.data;
    })
    .catch((err) => {
      //console.log("error", err);
    });
};

const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

const authService = {
  register,
  login,
  logout,
};

export default authService;
