import React, { useState, useEffect, Fragment } from "react";
import { Link, useParams } from "react-router-dom";

// import Card from "../../UI/Card";
import { Card } from "@mui/material";

import "./oppositeDefine.css";

import asalaaServices from "../../../../redux/services/zakra/asalaa/programs";

const AsalaaList = () => {
  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const sub = asalaaServices.main("asala").then((res) => {
      setCategory(res.data.asalas);
      setIsLoading(false)
      //console.log(res.data);
    });
  }, []);

  if (isLoading) return <></>;
  return (
    <section className="learning__program__secttion">
      <Card>
        <div className="learning__container">
          {category.map((t) => {
            // //console.log(t);
            // return <></>;
            return (
              <Fragment key={t.id}>
                <Link
                  className="program__details__btn"
                  to={`/zakra/asalaa/${t.asala_define.id}/${t.id}`}
                >
                  {t.name}
                </Link>
              </Fragment>
            );
          })}
        </div>
      </Card>
    </section>
  );
};

export default AsalaaList;
