import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import "./Choose.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Grid } from "@mui/material";
import whatsWrongServices from "../../../../redux/services/categories/whatsWrong/programs";

const WhatsWrongGame = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id, page } = useParams();
  const [nextBack, setNextBack] = useState({
    next: null,
    back: null,
  });
  const [images, setImages] = useState(null);

  const [text, setText] = useState('');
  useEffect(() => {
    whatsWrongServices.main("whatwrong", id).then((res) => {
      // setSound({
      //   choose: `https://maharttafl.com/sound/${res.data.sound.choose}`,
      //   item: `https://maharttafl.com/sound/${res.data.opposites.sound}`,
      //   right: `https://maharttafl.com/sound/${res.data.sound.right}`,
      //   wrong: `https://maharttafl.com/sound/${res.data.sound.wrong}`,
      // });
      setText(res.data.define.name);
      //console.log(res.data);
      setImages(res.data.define.avatar);
      setNextBack({ ...nextBack, next: res.data.next });
      setNextBack({ ...nextBack, back: res.data.previous });
      setIsLoading(false);
    });
  }, []);

  if (isLoading) return <></>;

  return (
    <div className="games__page">
      <div>
        <Grid
          container
          className="games__section container"
          justifyContent="center"
          textAlign="center"
        >
          <Grid item xs={12}>
            {" "}
            <img
              src={`https://maharttafl.com/images/${images}`}
              alt=""
              style={{
                width: "150px",
                height: "150px",
                border: "1px solid #dedede",
                borderRadius: "30%",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <h3>{text}</h3>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default WhatsWrongGame;
