import axiosRequest from "../../../../helpers/axiosRequest";
import env from "../../../../helpers/env";
const API_URL = env.baseURL;
const main = (url) => {
  return axiosRequest.get(API_URL + url);
};
const define = (url) => {
  return axiosRequest.get(API_URL + url);
};
const defineShow = (url, id, subId) => {
  return axiosRequest.get(API_URL + url + "/" + id + "/" + subId);
};
const choose = (url) => {
  return axiosRequest.get(API_URL + url);
};
const chooseShow = (url, id, subId) => {
  return axiosRequest.get(API_URL + url + "/" + id + "/" + subId);
};
const t3mem = (url, id) => {
  return axiosRequest.get(API_URL + url + "/" + id);
};
const tameez = (url, id) => {
  return axiosRequest.get(API_URL + url + "/" + id);
};
const verbUse = (url, id) => {
  return axiosRequest.get(API_URL + url + "/" + id);
};

const VerbsService = {
  main,
  define,
  defineShow,
  choose,
  chooseShow,
  t3mem,
  tameez,
  verbUse,
};

export default VerbsService;
