import React, { useState, useEffect, Fragment } from "react";
import { Link, useParams } from "react-router-dom";

// import Card from "../../UI/Card";
import { Card } from "@mui/material";

import "./adverbDefine.css";

import adverbServices from "../../../../../redux/services/categories/adverb/programs";
import preposServices from "../../../../../redux/services/categories/prepos/programs";

const PreposDefine = () => {
  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const sub = preposServices.define("preposbchoose").then((res) => {
      setCategory(res.data.opposites);
      setIsLoading(false)
      // //console.log(res.data)
    });
  }, []);

  
  if (isLoading) return <></>;
  return (
    <section className="learning__program__secttion">
      <Card>
        <div className="learning__container">
          {category.map((t) => {
            // //console.log(t);
            // return <></>;
            return (
              <Fragment key={t.id}>
                <Link
                  className="program__details__btn"
                  to={`/programs/prepos/define/${t.id}`}
                >
                  {t.name}
                </Link>
              </Fragment>
            );
          })}
        </div>
      </Card>
    </section>
  );
};

export default PreposDefine;
