import React, { Fragment } from "react";
import Card from "../../../UI/Card";
import SKA from "../../images/image 20.png";
import EGYPT from "../../images/image 21.png";
import { Avatar, Box, Grid, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Price.css";

const PriceItem = ({
	title,
	subtitle,
	icon,
	packages,
	id,
	iconColor,
	isRights,
	buttonColor,
}) => {
	const navigate = useNavigate();
	const packageTitle = packages?.title || "";
	return (
		<Card radius="20px" className="single-price">
			<Box mb={1} className="title-wrap">
				<Avatar sx={{ bgcolor: iconColor }} className="price-icon">
					{icon}
				</Avatar>
				<Typography variant="h6" className="price-title">
					{title}
				</Typography>
				<p>{subtitle}</p>
			</Box>
			{packages && (<></>
				// <div className="price-plans">
				// 	{/* NOTE ICONS */}
				// 	<div />
				// 	<div className="child icon">
				// 		<img src={EGYPT} className="img" />
				// 	</div>
				// 	<div className="child icon">
				// 		<img src={SKA} className="img" />
				// 	</div>

				// 	{packages.values.map((packg, index) => {
				// 		return (
				// 			/* FIXME must be unique id */
				// 			<Fragment key={index}>
								
				// 				<div className="child label">
				// 					{packg.plan} {packageTitle}
				// 				</div>
				// 				<div className="child value">{packg.egyPrice} جنيه</div>
				// 				<div className="child value">{packg.ksaPrice} ريال</div>
				// 			</Fragment>
				// 		);
				// 	})}
				// </div>
			)}
			<Box textAlign="center">
				{/* TODO On click , direct to plan with "id" prop */}
				<Button
					sx={{ bgcolor: buttonColor || "#deb50f" }}
					className="suscribe-button"
					onClick={()=>{navigate('/teachers/3574')}}
				>
					احجز جلستك الان
				</Button>
				{isRights && <span className="subtext">تطبق الشروط والاحكام</span>}
			</Box>
		</Card>
	);
};

export default PriceItem;
