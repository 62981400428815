import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import ProgramsSerivce from "../../../../redux/services/categories/Programs/programs";
import "./Define.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Grid } from "@mui/material";
import Carousel from "../../../../components/Carousel";
import { Item } from "../../../../components/Carousel/styles";
import partOfAllServices from "../../../../redux/services/categories/partofall/programs";
import visualServices from "../../../../redux/services/categories/visual/programs";
const Visual = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { page, id } = useParams();
  const [magmoaa, setMagmoaa] = useState({});
  const [images, setImages] = useState([]);
  const [right, setRight] = useState(null);
  const [nextBack, setNextBack] = useState({
    next: null,
    back: null,
  });
  const [text, setText] = useState("");
  const [sound, setSound] = useState({
    choose: null,
    right: null,
    wrong: null,
  });
  useEffect(() => {
    visualServices.main("visualdirection", id).then((res) => {
      // setMagmoaa(res.data);
      setSound({
        choose: `https://maharttafl.com/sound/${res.data.sound.choose}`,
        right: `https://maharttafl.com/sound/${res.data.sound.right}`,
        wrong: `https://maharttafl.com/sound/${res.data.sound.wrong}`,
      });
      //console.log(res.data);
      setText(res.data.define.name);
      setRight(res.data.define.right1);
      setImages(res.data.define.images);
      setNextBack({ ...nextBack, next: res.data.next });
      setNextBack({ ...nextBack, back: res.data.previous });
      // setSound(`https://maharttafl.com/sound/${res.data.content.sound}`);
      // setImages(res.data.content.photo.split(","));
      setIsLoading(false);
      // setImages([...images, res.data.content.photo.split(",")]);
    });
  }, []);
  if (isLoading) return <></>;

  const clapp = () => {
    let audio = new Audio(`https://maharttafl.com/sound/clapp.mp3`);
    audio.play();
  };
  return (
    <div className="games__page">
      <section className="games__section container">
        <div
          style={{ background: "white", textAlign: "center", width: "100%" }}
        >
          {text}
        </div>
        <img src={`https://maharttafl.com/images/${right}`} alt="" />
      </section>
      <div className="container">
        <Carousel className="games__section container" keys={false}>
          {images.map((t, index) => {
            return (
              <Item>
                <img
                  src={`https://maharttafl.com/images/${t.image}`}
                  alt=""
                  style={{
                    width: "150px",
                    height: "150px",
                    border: "1px solid #dedede",
                    borderRadius: "30%",
                  }}
                  onClick={() => {
                    if (t.type === "wrong") {
                      let audio = new Audio(sound.wrong);
                      audio.play();
                    }
                    if (t.type === "right") {
                      clapp();
                      setTimeout(() => {
                        let audio = new Audio(sound.right);
                        audio.play();
                      }, 2500);
                    }
                  }}
                />
              </Item>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Visual;
