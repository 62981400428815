import { Container, Paper } from "@mui/material";
import React, { useEffect,useState } from "react";
import SpeechExperts from "../SpeechExperts";
import "./TeacherDetails.css";
import TeacherComments from "./Comments";
import Title from "../../../components/Title";
import Card from "../../../UI/Card";
import { useParams } from "react-router-dom";
import axiosRequest from '../../../helpers/axiosRequest'

const expert = {
	id: "1t",
	name: "د. عمرو محمد",
	rating: 4,
	ilo: "<ul><li>هذا النص غير صحيح فقط للتجربه</li> <li>هذا النص غير صحيح فقط للتجربه</li> <li>هذا النص غير صحيح فقط للتجربه</li> <li>هذا النص غير صحيح فقط للتجربه</li></ul>",
};

const TeacherDetails = () => {
	const params = useParams();
	const [isLoading,setIsLoading] = useState(true);
	const [teacherData , setTeacher] = useState(null);
	useEffect(() => {
		window.scrollTo({ top: 0 });
		// console.log('ID=>',params.id);
		axiosRequest.get(`teacher/${params.id}`).then(res=>{
			console.log(res.data.teacher);
			setTeacher(res.data);
			
			setIsLoading(false);
		}).catch(err=>{
			console.log(err);
		})
		
		
	}, [params]);
	if(isLoading) return <></>
	return (
		<Container>
			<Paper className="teacher-details-wrap" elevation={0}>
				<SpeechExperts expert={teacherData.teacher} sessions={teacherData.sessions} />
			</Paper>
			<Card className="teacher-details-comments-wrap">
				<Title title={"التعليقات"} align={"right"} />
				<TeacherComments teacherComments={teacherData.teacherComments.data} teacherId={teacherData.teacher.id} />
			</Card>
		</Container>
	);
};

export default TeacherDetails;
